import { useLocation } from 'react-router';
import { getPermitedRoutes, notFoundRoute } from './routing/Routes';
import { withCommonFilterQuery } from './routing/utils/filters';
import { State as UserState } from './reducers/user-profile';
import { RoutesContext, renderRoutes } from './utils/routing';

// components
import { Footer, Header } from 'components';
import { Loader } from 'components/Controls';

import contentStyles from './styles';

type TProps = {
  user: UserState;
  isFetching?: boolean
}

export const Router = (props: TProps) => {
  const classes = contentStyles();
  const location = useLocation();

  // add pathParam form common-filters https://nwaveio.atlassian.net/browse/BNIV-1955
  const permittedRoutes = withCommonFilterQuery(getPermitedRoutes({
    userData: props.user,
  }), location);

  return (<RoutesContext.Provider value={ permittedRoutes }>
    <div className={ classes.root }>
      <Loader in={ props.isFetching } />
      <Header />
      <main className={ classes.content }>
        <div className={ classes.toolbar } />
        { renderRoutes({
          getChildRoutes: ({ route }) => {
            if (!route.routes || !route.routes.length) {
              return [];
            }

            return [...route.routes, notFoundRoute];
          },
          getRouteComponent: options => options.route.main,
          // FIXME: if you move this to a separate component,
          // replace `Routes` with `RoutesContext.Consumer`'s value
          routes: permittedRoutes,
        }) }
      </main>
      <Footer />
    </div>
  </RoutesContext.Provider>);
};
