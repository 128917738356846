import { Project } from 'models/user-management';
import { useProjectForm } from './useProjectForm';
import { useDialog } from 'hooks';

// components
import { BlockTitle, BlockToolbar } from 'components/Block';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { SuccessButton } from 'components/Buttons';
import { ProjectForm } from './ProjectForm';
import { DeleteDialog } from './DeleteDialog';

interface Props {
  project?: Partial<Project>;
  isAdmin: boolean;
  allowedToUpdate: boolean;
  allowedToDelete: boolean;
  onCreateSuccess?: (project: Partial<Project>) => void;
  onDeleteSuccess?: () => void;
  isEmptyProject?: boolean;
}

export const ProjectFormPaper = ({
  project,
  isAdmin,
  allowedToUpdate,
  allowedToDelete,
  onCreateSuccess,
  onDeleteSuccess,
  isEmptyProject,
}: Props): JSX.Element => {
  const { control, getValues, errors, isDirty, isValid, reset, updateProject, deleteProject } = useProjectForm({
    defaultValues: project,
    onCreateSuccess,
    onUpdateSuccess: project => { reset(project); },
    onDeleteSuccess,
  });
  const deleteDialog = useDialog();

  return (<>
    <Box overflow="visible">
      <BlockToolbar>
        <BlockTitle>Project information</BlockTitle>
        <Button disabled={!allowedToDelete} onClick={ () => { deleteDialog.open(); } }>
          Delete
        </Button>

        { isDirty && (
          <Button color="inherit" onClick={ () => reset(project) }>
            Cancel
          </Button>
        ) }

        <SuccessButton
          label="Save"
          onClick={async () => {
            const v = getValues();
            project?.id && await updateProject({ ...v , id: project.id });
          }}
          disabled={ !isDirty || !isValid || !allowedToUpdate }
          titleForTooltip={ isDirty ? '' : 'Nothing to save' }
        />
      </BlockToolbar>
      <br />
      <ProjectForm
        isAdmin={isAdmin}
        allowed={allowedToDelete}
        errors={errors}
        control={control}
      />
    </Box>

    {deleteDialog.isMounted && project && <DeleteDialog
      project={project}
      isEmptyProject={ isEmptyProject || false }
      onYes={ async () => project?.id && await deleteProject(project.id) }
      isOpen={ deleteDialog.isOpen }
      onClose={ deleteDialog.close }
      onCloseEnd={ deleteDialog.unmount }
    />}
  </>);
};
