import { staticEnv } from 'env';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { PositionGroupUpdateParams, Group } from 'models/device-management';
import { dmPositionGroupsPath } from 'routing/paths';
import { isAllowedToDeleteGroup, isAllowedToUpdateGroup } from 'utils/permissions';
import { useUpdateGroup } from 'hooks/device-management';
import { formatDateTime } from 'utils/datetime';
import * as is from 'utils/form/validation/validators';
import { nameof } from 'utils/type-checking';
import { showParkingAnomaly } from 'helpers';

import { useForm, Controller } from 'react-hook-form';

// components
import {
  Button as MuiButton,
  Grid,
  FormGroup,
  Paper,
  TextField,
} from '@material-ui/core';
import { ZoneSelectControl, LevelSelect } from 'components/Controls';
import { SelectPositionGroupType } from './SelectPositionGroupType';
import { SuccessButton } from 'components/Buttons';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { FieldSkeleton } from 'components/Skeleton';

import DeletePositionGroup from './DeletePositionGroup';
import { GroupParkingAnomaly } from 'components/ParkingAnomaly';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';
import { useStyles } from './style';
import { RootState } from 'reducers';

type PositionGroupFormValues = PositionGroupUpdateParams['props'];

export interface GeneralTabProps {
  group?: Group;
  returnUrl?: string;
}

const getDefaultValues = (group?: Group): Partial<PositionGroupFormValues> => ({
  ...group,
  custom_id: group?.custom_id ?? '',
  level_id: group?.level_id ?? undefined,
});

const GeneralTab: React.FC<GeneralTabProps> = ({ group, returnUrl = dmPositionGroupsPath }) => {
  const allowedToDeletePositionGroup = useSelector((state: RootState) => isAllowedToDeleteGroup(state.user.data));
  const allowedToUpdatePositionGroup = useSelector((state: RootState) => isAllowedToUpdateGroup(state.user.data));
  const history = useHistory();
  const classes = useStyles();
  const infoCss = useInfoBlockStyles();

  const { updateGroup } = useUpdateGroup();

  const onSubmit = async (values: PositionGroupFormValues, onErrorCallback: () => void) => {
    const groupUpdateProps = {
      ...group,
      ...values,
    };
    await updateGroup({
      id: group?.id as number,
      props: groupUpdateProps,
    },  onErrorCallback);
  };

  const form = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(group),
    validationSchema: {
      name: is.required(),
      type: is.required()
    },

  });

  if (!group) {
    return (
      <Paper>
        <FieldSkeleton className={ infoCss.field } />
      </Paper>
    );
  }

  return (
    <div className={ classes.root }>
      <div className={ classes.fullWidth }>
        <Paper>
          <BlockToolbar>
            <BlockTitle>
                Group information
            </BlockTitle>

            { allowedToDeletePositionGroup && (
              <DeletePositionGroup
                groupId={ group.id as number }
                onSuccess={ () => history.push(returnUrl) }
              />
            ) }

            { allowedToUpdatePositionGroup && form.formState.dirty && (
              <MuiButton color="inherit" onClick={ () => form.reset() }>
                  Cancel
              </MuiButton>
            ) }

            { allowedToUpdatePositionGroup && (
              <SuccessButton
                onClick={ () => onSubmit(form.getValues(), () => { form.reset(); }) }
                disabled={ !form.formState.dirty }
                pending={ form.formState.isSubmitting }
                titleForTooltip={ form.formState.dirty ? '' : 'Nothing to save' }
              />
            ) }
          </BlockToolbar>

          <FormGroup className={ infoCss.fields }>
            <TextField
              className={ infoCss.field }
              disabled
              label="Group ID"
              value={ group.id || '' }
            />

            <Controller
              disabled={ !allowedToUpdatePositionGroup }
              className={ infoCss.field }
              as={TextField}
              label="Group Name"
              name={ nameof<PositionGroupFormValues>('name') }
              control={form.control}
            />
            <div className={ infoCss.field }>
              <Controller
                isDisabled={ !allowedToUpdatePositionGroup }
                as={props => (<SelectPositionGroupType {...props} />)}
                label="Group Type"
                name={ nameof<PositionGroupFormValues>('type') }
                control={form.control}
              />
            </div>
            <div className={ infoCss.field }>
              <Controller
                isDisabled={ !allowedToUpdatePositionGroup }
                as={props => (<ZoneSelectControl
                  {...props}
                  selected={props.value}
                  onChange={e => {
                    props.onChange(e);
                    form.setValue('level_id', null);
                  }}
                />)}
                name={ nameof<PositionGroupFormValues>('zone_id') }
                control={form.control}
              />
            </div>
            <div className={ infoCss.field }>
              <Controller
                isDisabled={ !allowedToUpdatePositionGroup || !form.watch('zone_id') }
                as={props => (<LevelSelect
                  {...props}
                  selected={ props.value }
                  zoneId={ form.watch('zone_id') }
                />)}
                name={ nameof<PositionGroupFormValues>('level_id') }
                control={form.control}
              />
            </div>
            <TextField
              className={ infoCss.field }
              disabled
              label="Creation time"
              value={ group.creation_date ? formatDateTime(group.creation_date) : '' }
            />
            <Grid className={ infoCss.field } container>
              <Grid item xs>
                <Controller
                  as={TextField}
                  name={ nameof<PositionGroupFormValues>('custom_id') }
                  label="Custom ID"
                  disabled={ !allowedToUpdatePositionGroup }
                  fullWidth
                  control={form.control}
                />
              </Grid>

              { allowedToUpdatePositionGroup && (
                <MuiButton onClick={ () => form.setValue('custom_id', uuidv4()) }>
                    Generate
                </MuiButton>
              ) }
            </Grid>
          </FormGroup>
        </Paper>
      </div>
      { staticEnv.IS_PARKING_ANOMALY_ON && (group && showParkingAnomaly(group.type) && <GroupParkingAnomaly groupId={ group.id } />) }
    </div>
  );
};

export default GeneralTab;
