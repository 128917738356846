import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { RootState } from 'reducers';
import { clearQueryCache } from 'utils/react-query';
import { projectsPath } from 'routing/paths';
import { DoGetProjectById } from 'actions/projects';
import { Project, User, UserRoleEnum } from 'models/user-management';
import { fetchUsers } from 'actions/user-management/users';
import { useAuthUserSelector } from 'hooks';
import { useStationLocationsSelector } from 'hooks/station';
import { useZones } from 'hooks/device-management';

import {
  isAllowedToReadZones,
  isAllowedToReadUsers,
  isAllowedToUpdateUser,
  isAllowedToDeleteUser,
  canViewStationList,
} from 'utils/permissions';

// components
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { PageLoader } from 'components/Loaders';
import { NoMatch } from 'pages';
import { ProjectFormPaper } from 'widgets/ProjectForm';
import { Zones, LocationsStations, AllowedUsers, EmailSubscription } from './widgets';

// styles
import { useStyles } from '../ProjectsStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { getMuiTheme } from 'styles/themes';

interface Props {
  projectId: number;
}

const filterUser = (user: User, project: Project, isAdmin: boolean) => {
  return ((user.user_group === project.owner_id) || (isAdmin && user.role === UserRoleEnum.Admin))
    && (
      user.projects === 'all'
      || user.projects.includes(project.id)
    );
};

const sortCurrentUserToTop = (users: User[], userId?: string) => [...users]
  .sort((u1, u2) => String(u1.id) === userId ? -1 : String(u2.id) === userId ? 1 : 0);

export const ProjectPersonalPage = ({ projectId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const { byId: project, isFetchingById } = useSelector((state: RootState) => state.projects.detailPage);
  const zonesFetching = useSelector((state: RootState) => state.deviceManagement.zones.repository.isFetching());
  const { lastEditedProject, isFetching: isFetchingEdit } = useSelector((state: RootState) => state.projects.detailPage.editProject);
  const { zones } = useZones({ params: {} });
  const { locations, isLoading } = useStationLocationsSelector({ projectId });
  const { data: users, usersFetching } = useSelector((state: RootState) => state.um);
  const authUser = useAuthUserSelector();
  const sortedUsers = sortCurrentUserToTop(users, authUser.data.user_id);

  const allowedToReadZones = isAllowedToReadZones(authUser.data);
  const allowedToReadUsers = isAllowedToReadUsers(authUser.data);
  const allowedToUpdateUsers = isAllowedToUpdateUser(authUser.data);
  const allowedToDeleteProject = isAllowedToDeleteUser(authUser.data);
  const allowedToAddUsers = allowedToReadUsers && allowedToUpdateUsers;

  useEffect(() => {
    projectId && dispatch(DoGetProjectById(projectId));
    dispatch(fetchUsers({ projects: [projectId], offset: 0, limit: 1000 }));
  }, [dispatch, projectId, lastEditedProject]);

  const isEmptyProject = !zones.filter(z => z.project_id === project?.id).length && !locations.length;

  return (
    <>
      <Helmet>Project { projectId }</Helmet>
      { (isEmpty(project) || project?.id !== projectId) ? (
        isFetchingById ? <PageLoader /> : <NoMatch />)
        : (
          <Box marginTop="20px">
            <MuiThemeProvider theme={ getMuiTheme({ embeddedTable: true }) }>
              { isFetchingEdit && <PageLoader /> }
              <Box className={ classes.wrapper }>
                <Box className={ classes.column }>
                  <Box className={ classes.block }>
                    <Paper>
                      <Box p={2}>
                        <ProjectFormPaper
                          project={project}
                          isAdmin={authUser.isAdmin}
                          allowedToUpdate={ allowedToUpdateUsers || false }
                          allowedToDelete={ (allowedToDeleteProject && !zonesFetching && !isLoading) || false }
                          onDeleteSuccess={ async () => {
                            history.push(projectsPath);
                            await clearQueryCache('userManagement/project');
                          }}
                          isEmptyProject={isEmptyProject} // TODO: remove this when back-end will be ready
                        />
                      </Box>
                    </Paper>
                  </Box>
                  { project && canViewStationList(authUser) && (
                    <Box className={ classes.block }>
                      <LocationsStations
                        isLoading={ isLoading }
                        locations={ locations }
                      />
                    </Box>
                  ) }
                  { allowedToReadUsers && project && (
                    <Box className={ classes.block }>
                      <AllowedUsers
                        users={ sortedUsers.filter(user => filterUser(user, project, authUser.isAdmin)) }
                        isFetching={ usersFetching }
                        project={ project }
                        allowedToAddUsers={ !!allowedToAddUsers }
                      />
                    </Box>
                  ) }
                </Box>

                <Box className={ classes.column }>
                  <Box className={ classes.block }>
                    <EmailSubscription
                      allowedToUpdateUsers={allowedToUpdateUsers}
                      usersFetching={usersFetching}
                      projectId={projectId}
                      ownerId={project.owner_id}
                      users={ sortedUsers.filter(user => filterUser(user, project, authUser.isAdmin))}
                      activeUserId={Number(authUser.data.user_id)}
                    />
                  </Box>
                  { allowedToReadZones && project && (
                    <Box className={ classes.block }>
                      <Zones
                        zones={ zones }
                        isFetching={ zonesFetching }
                        project={ project }
                      />
                    </Box>
                  ) }
                </Box>

              </Box>
            </MuiThemeProvider>
          </Box>) }
    </>
  );
};
