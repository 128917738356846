import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { bindDeviceToPosition } from 'actions/device-management/devices';
import { NotifyError } from 'actions/notifier';
import { Device, ExistingPosition } from 'models/device-management';
import useDeviceInputLoader from 'pages/DevicePositionCouple/widgets/useDeviceInputLoader';
import { dispatchAsync } from 'utils/store';

// components
import { Grid, TextField, Paper } from '@material-ui/core';

import { Button, ConfirmationDialog, ConfirmationDialogProps } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';

// styles
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface DeviceBindingProps {
  positionId: ExistingPosition['id'];
  onSuccess?: () => void;
}

function getConfirmationDialogText(device: Device): Partial<ConfirmationDialogProps> | null {
  if (device && device.damaged_status) {
    return {
      title: 'Are you sure?',
      description: 'Do you really want to bind the damaged device to the current position?'
    };
  }

  return null;
}

const DeviceBinding: React.FC<DeviceBindingProps> = ({ positionId, onSuccess }) => {
  const {
    inputValue,
    setInputValue,

    device,
    deviceId,
    devicePending,
  } = useDeviceInputLoader();
  const [submitPending, setSubmitPending] = useState(false);

  const dispatch = useDispatch();
  const notifyError = (message: string) => dispatch(NotifyError(message));

  const infoCss = useInfoBlockStyles();

  const submitParams = { deviceId, positionId };
  const submit = async () => {
    await dispatchAsync(dispatch, bindDeviceToPosition(submitParams));
    onSuccess?.();
  };

  return (
    <Paper>
      <BlockToolbar>
        <BlockTitle>
          Bind device
        </BlockTitle>
      </BlockToolbar>

      <Grid alignItems="center" container>
        <TextField
          className={infoCss.field}
          label="Device ID"
          placeholder="Enter the device ID"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />

        <ConfirmationDialog
          {...(device && getConfirmationDialogText(device))}
          renderTrigger={(modal) => (
            <ThemeProvider theme={successTheme}>
              <Button
                CircularProgressProps={{ color: 'secondary', size: '1.1rem' }}
                className={infoCss.button}
                color="primary"
                pending={devicePending || submitPending}
                variant="contained"
                onClick={() => {
                  if (typeof device === 'undefined') {
                    return;
                  }

                  if (device === null) {
                    notifyError(`Device "${deviceId}" not found`);
                    return;
                  }

                  if (device.position_id) {
                    notifyError(`Device "${deviceId}" is already bound to another position`);
                    return;
                  }

                  if (device.damaged_status) {
                    modal.open();
                    return;
                  }

                  setSubmitPending(true);
                  submit().finally(() => setSubmitPending(false));
                }}
              >
                Bind
              </Button>
            </ThemeProvider>
          )}
          onConfirm={(modal) => {
            modal.setPending(true);

            submit()
              .then(modal.close)
              .catch(() => modal.setPending(false));
          }}
        />
      </Grid>
    </Paper>
  );
};

export default DeviceBinding;
