import { BaseStation } from 'models/base-station';
import { StationStatusTitle } from 'models/base-station-status';

import greenMarker from 'images/Map/MarkerRadioGreen.png';
import yellowMarker from 'images/Map/MarkerRadioYellow.png';
import redMarker from 'images/Map/MarkerRadioRed.png';
import greyMarker from 'images/Map/MarkerIconTransparentGrey.svg';
// components
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Box, Typography } from '@material-ui/core';
import { BaseStationFrequency } from 'components/Labels';
import { BaseStationLink } from 'components/Links';
import { StatusIcon } from './StatusIcon';

interface Props {
  station: BaseStation;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
}

const getMarkerColor = (isOnline: boolean, isHealthy: boolean) => {
  switch (true) {
    case isOnline && isHealthy:
      return greenMarker;
    case isOnline && !isHealthy:
      return yellowMarker;
    case !isOnline:
      return redMarker;
    default:
      return greyMarker;
  }
};

export const StationMarker = (props: Props): JSX.Element => {
  const { station, isSelected, onSelect, onClose } = props;

  const isOnline = !station?.status?.isOffline;
  const isHealthy = !station?.status?.hasIssues;

  const location = [
    station.location?.postcode,
    station.location?.country,
    station.location?.city,
    station.location?.address
  ].filter(l => !!l).join(' ');
  const frequency = station.frequency ? <BaseStationFrequency stationFrequency={ station.frequency }/> : '-';
  const position = { lat: station.location?.lat ?? 0, lng: station.location?.lon ?? 0 };
  return (
    <Marker
      onClick={ onSelect }
      position={ position }
      icon={ getMarkerColor(isOnline, isHealthy) }
    >
      { !isSelected ? '' :
        <InfoWindow onCloseClick={ onClose } position={ position }>
          <Box lineHeight={ 1 }>
            <StatusIcon
              status={ isOnline }
              label={ isOnline ? StationStatusTitle.Online : StationStatusTitle.Offline }
              lastChange={ station?.status?.offlineChanged }
            />
            <StatusIcon
              status={ isHealthy }
              label={ isHealthy ? StationStatusTitle.Healthy : StationStatusTitle.Unhealthy }
              lastChange={ station?.status?.issueChanged }
            />
            <Typography variant="subtitle2">
              ID: <Typography variant="caption"><BaseStationLink stationId={ station.id }/></Typography>
            </Typography>
            <Typography variant="subtitle2">
              Location: <Typography variant="caption">{ location }</Typography>
            </Typography>
            <Typography variant="subtitle2">
              Frequency: <Typography variant="caption">{ frequency }</Typography>
            </Typography>
          </Box>
        </InfoWindow>
      }
    </Marker>
  );
};
