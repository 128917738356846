import { Project } from 'models/user-management';
import { useProjectForm } from './useProjectForm';
import { useDialog } from 'hooks';
import { useStyles } from './ProjectFormStyles';

// components
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import { AddButton, SuccessButton } from 'components/Buttons';
import { ProjectForm } from './ProjectForm';

interface Props {
  isAdmin: boolean;
  allowed: boolean;
  onCreateSuccess?: (project: Partial<Project>) => void;
}
export const ProjectFormModal = ({
  isAdmin,
  allowed,
  onCreateSuccess,
}: Props): JSX.Element => {
  const classes = useStyles();
  const dialog = useDialog();
  const { control, getValues, errors, isValid,  createProject } = useProjectForm({ onCreateSuccess });

  return (<>
    <AddButton label="Add Project" onClick={ dialog.open } />
    { dialog.isMounted && <Dialog
      open={ dialog.isOpen }
      onClose={ dialog.close }
      onExited={ dialog.unmount }
      maxWidth={ 'sm' }
      fullWidth
      PaperProps={ { className: classes.formDialogContent } }
    >
      <DialogTitle>Create Project</DialogTitle>
      <DialogContent className={classes.formDialogContent}>
        <Box overflow="visible">
          <ProjectForm
            isAdmin={isAdmin}
            allowed={allowed}
            errors={errors}
            control={control}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={ dialog.close }>
          Cancel
        </Button>
        <SuccessButton
          label="Create"
          onClick={async () => {
            const v = getValues();
            await createProject(v);
          }}
          disabled={ !isValid }
        />
      </DialogActions>
    </Dialog>}
  </>);
};
