// components
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { ZoneFormWidget } from 'widgets/ZoneForm/ZoneForm';

export const CreateZone = (): JSX.Element => {
  return (<Box p={2}>
    <Helmet>
      <title>Create zone</title>
    </Helmet>
    <Paper>
      <ZoneFormWidget />
    </Paper>
  </Box>);
};
