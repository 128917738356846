import React from 'react';
import { useDispatch } from 'react-redux';

import { unbindDeviceFromPosition } from 'actions/device-management/devices';
import { Device, ExistingPosition } from 'models/device-management';
import { dispatchAsync } from 'utils/store';

// components
import { Button } from '@material-ui/core';

import { ConfirmationDialog } from 'components';

// styles
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';
import { dangerTheme } from 'styles/themes';

interface UnbindDeviceProps {
  deviceId: Device['device_id'];
  positionId: ExistingPosition['id'];
  onSuccess?: () => void;
}

const UnbindDevice: React.FC<UnbindDeviceProps> = (props) => {
  const dispatch = useDispatch();
  const infoCss = useInfoBlockStyles();

  return (
    <ConfirmationDialog
      title={'Are you sure?'}
      description={'The device will be unbound'}
      renderTrigger={(modal) => (
        <ThemeProvider theme={dangerTheme}>
          <Button
            className={infoCss.field}
            color="primary"
            variant="contained"
            onClick={modal.open}
          >
            Unbind
          </Button>
        </ThemeProvider>
      )}
      onConfirm={(modal) => {
        modal.setPending(true);

        const actionParams = {
          deviceId: props.deviceId,
          positionId: props.positionId,
        };

        dispatchAsync(dispatch, unbindDeviceFromPosition(actionParams))
          .then(() => {
            modal.close();
            props.onSuccess?.();
          })
          .catch(() => modal.setPending(false));
      }}
    />
  );
};

export default UnbindDevice;
