import React from 'react';
import { useDispatch } from 'react-redux';

import { updatePosition } from 'actions/device-management/positions';
import { useZoneForPosition } from 'hooks/device-management';
import { Device, Position, ExistingPosition } from 'models/device-management';
import { dmPositionPath } from 'routing/paths';
import { isAllowedToDeletePosition, isAllowedToUpdatePosition } from 'utils/permissions';
import { useForm } from 'utils/form';
import * as is from 'utils/form/validation/validators';
import { combineIds } from 'utils/ids';
import { Defined, isNil } from 'utils/models';
import { dispatchAsync } from 'utils/store';
import { useAuthUserSelector } from 'hooks';
import { PositionFormValues } from './utils';

// components
import { Button as MuiButton, Link, Paper, Tooltip, Box } from '@material-ui/core';
import { Button, FormContext } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { CustomLink } from 'components/Links';
import { FieldSkeleton } from 'components/Skeleton';
import PositionBinding from '../PositionBinding';
import PositionGeneralInfo from '../PositionGeneralInfo';
import DeletePosition from './DeletePosition';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';


export interface PositionBlockProps {
  dependent?: boolean;
  device?: Device;
  position?: ExistingPosition;
  positionId?: Defined<Position['id']> | null;
  idPrefix?: string;
  onDevicePositionRelationUpdate?: () => void;
  onPositionDelete?: () => void;
}

export interface MainMarkerPosition {
  lat?: number;
  lng?: number;
}

const getDefaultValues = (position?: ExistingPosition, zoneId?: number): PositionFormValues => ({
  ...position,

  zone_id: zoneId,
  custom_id: position?.custom_id ?? '',
  network_id: position?.network_id ?? '',
  group_inner_id: position?.group_inner_id ?? '',
  lat: position?.lat ?? '',
  lon: position?.lon ?? '',
  floor_number: position?.floor_number ?? '',
});

const PositionBlock: React.FC<PositionBlockProps> = ({
  dependent = false,
  device,
  position,
  positionId,
  idPrefix,
  onDevicePositionRelationUpdate,
  onPositionDelete,
}) => {
  const { isAdmin, clientId, data: user } = useAuthUserSelector();
  const allowedToDeletePosition = isAllowedToDeletePosition(user);
  const allowedToUpdatePosition = isAllowedToUpdatePosition(user);
  const infoCss = useInfoBlockStyles();

  const zoneQuery = useZoneForPosition(position);
  const positionOwnerId = isAdmin ? zoneQuery.zone?.owner_id : clientId;

  const dispatch = useDispatch();

  const form = useForm<PositionFormValues>({
    defaultValues: getDefaultValues(position, zoneQuery.zone?.id),
    validators: {
      lat: is.required(),
      lon: is.required(),
      group_inner_id: is.required(),
      group_id: is.required(),
      zone_id: is.required(),
    },
  });

  const handleZoneChange = () => {
    form.setValue('group_id', undefined);
    form.setValue('group_inner_id', '');
  };

  const handlePositionGroupChange = () => {
    form.setValue('group_inner_id', '');
  };

  const handleChangeLat = (value: React.ReactText) => form.setValue('lat', value);

  const handleChangeLon = (value: React.ReactText) => form.setValue('lon', value);

  const handleSubmit = form.handleSubmit((values) => {
    const updatedPosition: ExistingPosition = {
      ...position as ExistingPosition,
      ...values,

      group_inner_id: Number(values.group_inner_id || undefined),
      custom_id: values.custom_id,

      floor_number: isNil(values.floor_number) ? undefined : Number(values.floor_number),
      lat: Number(values.lat || undefined),
      lon: Number(values.lon || undefined),
    };
    return dispatchAsync(dispatch, updatePosition(updatedPosition));
  });

  if (positionId === null && device) {
    return (
      <PositionBinding
        device={ device }
        onSuccess={ onDevicePositionRelationUpdate }
      />
    );
  }

  if (!position || !positionId) {
    return (
      <Paper className={ infoCss.root }>
        <FieldSkeleton className={ infoCss.field } />
      </Paper>
    );
  }

  return (
    <FormContext form={ form }>
      <form data-testid="position-block" onSubmit={ handleSubmit }>
        <Paper className={ infoCss.root }>
          <BlockToolbar>
            <BlockTitle>
              <Box padding="0px 10px">
                {/* replace with `props.renderTitle()` if more customization is needed */ }
                { dependent ? 'Bound position' : 'Position' }{ ' ' }
                <Link
                  color="secondary"
                  component={ CustomLink }
                  to={ dmPositionPath(positionId) }
                  activeAsText
                  withReturnUrl
                >
                  { position.network_id }
                </Link>
              </Box>
            </BlockTitle>

            { allowedToDeletePosition && (
              <DeletePosition
                deviceId={ device?.device_id }
                positionId={ positionId }
                onSuccess={ onPositionDelete }
              />
            ) }

            { allowedToUpdatePosition && form.formState.dirty && (
              <MuiButton color="inherit" onClick={ form.reset }>
                Cancel
              </MuiButton>
            ) }
            { allowedToUpdatePosition && (
              <Tooltip title={ form.formState.dirty ? '' : 'Nothing to save' }>
                <span>
                  {/* disabled buttons don't fire events, so we need a wrapper like <span> */ }
                  <MuiThemeProvider theme={ successTheme }>
                    <Button
                      color="primary"
                      disabled={ !form.formState.dirty }
                      pending={ form.formState.isSubmitting }
                      type="submit"
                    >
                      Save
                    </Button>
                  </MuiThemeProvider>
                </span>
              </Tooltip>
            ) }
          </BlockToolbar>

          <PositionGeneralInfo
            idPrefix={ combineIds(idPrefix, 'general') }
            position={ position }
            positionOwnerId={ positionOwnerId }
            onZoneChange={ handleZoneChange }
            onPositionGroupChange={ handlePositionGroupChange }
            device={ device }
            changeLat={ handleChangeLat }
            changeLon={ handleChangeLon }
          />
        </Paper>
      </form>
    </FormContext>
  );
};

export default PositionBlock;
