// components
import { Grid } from '@material-ui/core';
import { CreateUserForm } from './CreateUserForm';

// styles
import { useStyles } from './styles';

export const CreateUserPage = () => {
  const classes = useStyles();
  return (<>
    <Grid container className={classes.wrap}>
      <Grid item xs={12} sm={12} md={6}>
        <CreateUserForm />
      </Grid>
    </Grid>
  </>);
};