import clsx from 'clsx';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { connect, ConnectedProps } from 'react-redux';

import { PositionsFilterFields } from 'models/device-management/filters';
import { usePositions } from 'hooks/device-management';

import { RootState } from 'reducers';
import { useControlledState } from 'utils/components';

import { PositionsTabId } from './utils';
import { usePositionsPageParams } from './usePositionsPageParams';

// components
import { Tab, Tabs } from '@material-ui/core/';
import { PositionsTable, PositionsTableProps } from 'components/DeviceManagement';

import { PositionsMap } from './PositionsMap';
import { PositionLegend } from './PositionsMap/PositionLegend';
import { LegendPopover } from 'components/Popover';

import { PositionsFilter } from './widgets/PositionsFilter';

// styles
import useStyles from 'styles/page';
import usePositionsStyles from './PositionsStyle';

interface TabPanelProps {
  children?: JSX.Element;
  index: number;
  value: number;
}

const mapState = (state: RootState) => ({
  isAdmin: state.user.isAdmin,
});


const connector = connect(
  mapState
);

type PositionsProps = ConnectedProps<typeof connector> & {
  children?: React.ReactNode;
  filtersHidden?: boolean;
  filtersInUrlHidden?: boolean
  filters?: Partial<PositionsFilterFields>;
  tabContentClassName?: string;
  tabsClassName?: string;
  tabsHidden?: boolean;
  activeTabId?: PositionsTabId;
  tableProps?: Partial<PositionsTableProps>;
}

const Positions = (props: PositionsProps): JSX.Element => {
  const classes = {
    ...useStyles(),
    ...usePositionsStyles()
  };
  const { isAdmin } = props;

  const [tab, setTab] = useControlledState({
    controlled: typeof props.activeTabId !== 'undefined',
    state: props.activeTabId ?? PositionsTabId.TABLE,
  });

  const { params, filters, setFilters } = usePositionsPageParams(props.filters);

  const { positions, isLoading, total } = usePositions(tab === PositionsTabId.MAP ? filters : params);

  const TabPanel = ({ children, index }: TabPanelProps) => {
    if (tab !== index) {
      return null;
    }
    return (
      <div className={ props.tabContentClassName }>
        { children }
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Positions</title>
      </Helmet>
      { !props.filtersHidden && (
        <PositionsFilter
          isLoading={ isLoading }
          filters={ filters }
          updateFilters={ setFilters }
        />
      )}
      { !props.tabsHidden && (
        <Tabs
          className={ clsx(classes.tabs, props.tabsClassName) }
          value={ tab }
          onChange={ (_, newTab) => setTab(Number(newTab)) }
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs"
        >
          <Tab label="Table" value={ PositionsTabId.TABLE } />
          <Tab label="Map" value={ PositionsTabId.MAP } />
          { tab === PositionsTabId.MAP && (
            <LegendPopover>
              <PositionLegend />
            </LegendPopover>
          ) }
        </Tabs>
      ) }

      <TabPanel value={ tab } index={ PositionsTabId.TABLE }>
        <PositionsTable
          isAdmin={ isAdmin }
          positions={ positions }
          total={ total }
          filters={ filters }
          isFetching={ isLoading }
          { ...props.tableProps }
        />
      </TabPanel>

      <TabPanel value={ tab } index={ PositionsTabId.MAP }>
        {
          <PositionsMap isLoading={ isLoading } positions={positions} />
        }
      </TabPanel>
    </>
  );
};

export default connector(Positions);
