import React from 'react';

// components
import { InfoWindow } from '@react-google-maps/api';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface Props {
  onCloseClick: () => void;
  positionId?: number;
  networkId?: string;
  icon: string;
  deviceId?: string;
  position: {
    lat: number,
    lng: number,
  };
}

export const InfoWindowComponent = ({ onCloseClick, positionId, networkId, deviceId, icon, position }: Props): JSX.Element => {
  return (
    <InfoWindow onCloseClick={ onCloseClick } position={ position }>
      <Box>
        <Typography variant="subtitle2">
          ID: <Typography variant="caption">{ `${ positionId }` }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Network ID: <Typography variant="caption">{ networkId ? `${ networkId }` : '-' }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Device ID: <img src={ icon } alt="icon" /> <Typography variant="caption">
            { deviceId || 'Position is free' }
          </Typography>
        </Typography>
      </Box>
    </InfoWindow>
  );
};
