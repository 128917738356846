import React from 'react';
import { DeviceWithConnectivity } from 'models/device-management/dm';
import greyMarker from 'images/Map/red-ico.png';
import redMarker from 'images/Map/red-ico.png';
import greenMarker from 'images/Map/green-ico.png';
import yellowMarker from 'images/Map/yellow-ico.png';
// components
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Box, Typography } from '@material-ui/core';

interface Props {
  device: DeviceWithConnectivity;
  isSelected: boolean;
  onSelect: () => void;
  onClose: () => void;
}

const getMarkerColor = (connectivity?: number) => {
  if (connectivity === undefined) {
    return greyMarker;
  } else if (connectivity >= 98) {
    return greenMarker;
  } else if (connectivity >= 95) {
    return yellowMarker;
  } else {
    return redMarker;
  }
};

export const DeviceMarker = (props: Props): JSX.Element => {
  const { device, isSelected, onSelect, onClose } = props;
  const position = { lat: device.lat ?? 0, lng: device.lon ?? 0 };
  return (
    <Marker
      onClick={ onSelect }
      position={ position }
      icon={ getMarkerColor(device.connectivity?.connectivity) }
    >
      { !isSelected ? '' :
        <InfoWindow onCloseClick={ onClose } position={ position }>
          <Box lineHeight={ 1 }>
            <Typography variant="subtitle2">
              { device.device_id }
            </Typography>
            <Typography variant="subtitle2">
              Connectivity: <Typography variant="caption">{ `${ device.connectivity?.connectivity ?? 0 }%` }</Typography>
            </Typography>
          </Box>
        </InfoWindow>
      }
    </Marker>
  );
};
