import React from 'react';
import { match } from 'react-router';

// components
import {
  AccountCircle,
  History,
  Home,
  Language,
  Message,
  Router,
  Tune,
  DeviceHub,
  Assessment,
  Queue,
  BugReport,
  Grain,
  TripOrigin,
  LinearScale,
  Place,
  FlashOn,
  Group,
  Chat,
  PermMedia,
  Timelapse,
  BlurCircular,
  LocalShipping,
  NextWeek,
  Archive,
  Ballot,
  TimeToLeave,
  AccountTree
} from '@material-ui/icons';


export interface MenuIconProps {
  icon?: (
    | 'global' | 'queue' | 'home' | 'settings' | 'messages' | 'rabbit' | 'router' | 'assessment'
    | 'history' | 'bug' | 'AccountCircle' | 'grain' | 'tripOrigin' | 'linearScale' | 'place'
    | 'flashOn' | 'parking' | 'users' | 'routes' | 'historyParking' | 'suite' | 'dmTree'
    | 'projects' | 'shipment' | 'nextWeek' | 'archive' | 'ballot'
  );
  match?: match<Record<string, string | undefined>> | null;
}

const MenuIcon = ({ icon, match }: MenuIconProps) => {
  const color = match ? 'primary' : undefined;

  switch (icon) {
    case 'global':
      return <Language color={ color } />;
    case 'queue':
      return <Queue color={ color } />;
    case 'home':
      return <Home color={ color } />;
    case 'settings':
      return <Tune color={ color } />;
    case 'messages':
      return <Message color={ color } />;
    case 'rabbit':
      return <DeviceHub color={ color } />;
    case 'router':
      return <Router color={ color } />;
    case 'assessment':
      return <Assessment color={ color } />;
    case 'history':
      return <History color={ color } />;
    case 'bug':
      return <BugReport color={ color } />;
    case 'AccountCircle':
      return <AccountCircle color={ color } />;
    case 'grain':
      return <Grain color={ color } />;
    case 'tripOrigin':
      return <TripOrigin color={ color } />;
    case 'linearScale':
      return <LinearScale color={ color } />;
    case 'place':
      return <Place color={ color } />;
    case 'flashOn':
      return <FlashOn color={ color } />;
    case 'parking':
      return <TimeToLeave color={ color } />;
    case 'users':
      return <Group color={ color } />;
    case 'routes':
      return <Chat color={ color } />;
    case 'historyParking':
      return <Timelapse color={ color } />;
    case 'suite':
      return <PermMedia color={ color } />;
    case 'projects':
      return <BlurCircular color={ color } />;
    case 'shipment':
      return <LocalShipping color={ color } />;
    case 'nextWeek':
      return <NextWeek color={ color } />;
    case 'archive':
      return <Archive color={ color } />;
    case 'ballot':
      return <Ballot color={ color } />;
    case 'dmTree':
      return <AccountTree color={ color } />;
    default:
      return null;
  }
};

export default MenuIcon;
