import React from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { User } from 'models/user-management';
import { RootState } from 'reducers';
import { isAllowedToUpdateUser, isAllowedToDeleteUser } from 'utils/permissions';
import { getUserListPath } from 'routing/paths/user-management';
import { useHistory } from 'react-router-dom';

// components
import { Box, Button, FormGroup, Paper, TextField } from '@material-ui/core';

import { BlockTitle, BlockToolbar } from 'components/Block';
import { OwnerName } from 'components/Labels';
import { FieldSkeleton } from 'components/Skeleton';
import { DeleteUser } from '../../../DeleteUser';
import { UserNotConfirmed } from 'components/UserManagement';
import ConfirmAccount from './ConfirmAccount';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';
import { ChangeUserPasswordControl } from 'components/ChangeUserPassword/ChangeUserPasswordControl';

interface AccountReportProps {
  user?: User;
  onEdit: () => void;
}

const AccountReport: React.FC<AccountReportProps> = (props) => {
  const editingAllowed = useSelector((state: RootState) => isAllowedToUpdateUser(state.user.data));
  const allowedToDeleteUsers = useSelector((state: RootState) => isAllowedToDeleteUser(state.user.data));
  const authUser = useSelector((state: RootState) => state.user.data);
  const isAdmin = useSelector((state: RootState) => state.user.isAdmin);
  const infoCss = useInfoBlockStyles();
  const { user } = props;
  const history = useHistory();

  const isCurrentUser = authUser.sub === user?.sub;

  return (
    <Paper data-testid="account-report">
      <BlockToolbar>
        <BlockTitle>
          <Box component="span" display="flex" alignItems="start">
            Account
            <UserNotConfirmed user={ user } />
          </Box>
        </BlockTitle>

        <ConfirmAccount
          user={ user }
          authUser={ authUser }
        />

        { user?.sub && user?.confirmed &&
          <ChangeUserPasswordControl
            isCurrentUser={ isCurrentUser }
            isAdmin={ isAdmin }
            userSub={ user.sub }
          />
        }

        { editingAllowed && (
          <Button color="inherit" onClick={ props.onEdit }>
            Edit
          </Button>
        ) }

        { ((allowedToDeleteUsers && user) || (user && isCurrentUser)) && (
          <DeleteUser
            key="deleteUser"
            userSub={ user.sub }
            onSuccess={ async () => {
              if (!isCurrentUser) { history.push(getUserListPath());}
              if (isCurrentUser) {
                await Auth.signOut();
                history.push('/');
              }
            } }
          />
        ) }
      </BlockToolbar>

      { user
        ? (
          <FormGroup className={ infoCss.fields }>
            <TextField
              className={ infoCss.field }
              disabled
              label="First name"
              value={ user.first_name }
            />

            <TextField
              className={ infoCss.field }
              disabled
              label="Last name"
              value={ user.last_name }
            />

            <TextField
              className={ infoCss.field }
              disabled
              label="Email"
              value={ user.email }
            />

            <TextField
              className={ infoCss.field }
              disabled
              label="Role"
              value={ user.role }
            />

            { isAdmin && (
              <OwnerName ownerId={ user.user_group }>
                { ({ userGroup }) => (
                  <TextField
                    className={ infoCss.field }
                    disabled
                    label="User Group"
                    value={ userGroup?.name ?? '' }
                  />
                ) }
              </OwnerName>
            ) }
          </FormGroup>
        )
        : <FieldSkeleton className={ infoCss.field } />
      }
    </Paper>
  );
};

export default AccountReport;
