import clsx from 'clsx';
import React, { useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
// components
import FormControl from '@material-ui/core/FormControl';
import ReactSelect from './ReactSelect';
import { SelectProps } from './types';
// styles
import contentStyles from 'styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SelectControl = (props: SelectProps<any>): JSX.Element => {
  const classes = contentStyles();
  const { isMulti, selected, options, onChange, checkSelected } = props;

  const getSelectedValue = useCallback((): typeof selected => {
    return isMulti
      ? options.filter(o => selected.includes(o.value))
      : options.find(o => o.value === selected);
  }, [isMulti, options, selected]);

  const getChangedValue = useCallback((option) => {
    if (undefined === option) {
      return undefined;
    }

    return isMulti
      ? options.filter(o => option.includes(o)).map(o => o.value)
      : options.find(o => o === option)?.value;
  }, [isMulti, options]);

  useEffect(() => {
    if (
      checkSelected
      && selected !== undefined
      && !isEqual(selected, getChangedValue(getSelectedValue()))
    ) {
      onChange(getChangedValue(getSelectedValue()));
    }
  }, [checkSelected, getChangedValue, getSelectedValue, onChange, selected]);

  return (
    <FormControl
      disabled={ props.isDisabled }
      required={ props.isRequired }
      error={ props.error ? true : false }
      { ...props.ControlProps }
      className={ clsx(classes.formControl, props.ControlProps?.className) }
      data-testid={ props.dataTestId }
    >
      <ReactSelect
        isMulti={ isMulti }
        label={ props.label }
        name={ props.name }
        placeholder={ props.placeholder }
        searchConfig={ props.searchConfig }
        options={ options }
        value={ getSelectedValue() ?? null }
        onChange={ (option, action) => onChange(getChangedValue(option), action) }
        error={ props.error }
        isClearable={ props.isClearable }
        isSearchable={ props.isSearchable }
        isDisabled={ props.isDisabled || (options.length === 0 && !props.hideMenu) }
        isLoading={ props.isLoading }
        isRequired={ props.isRequired }
        isPortal={ props.isPortal }
        isMultiLine={ props.isMultiLine }
        hideMenu={ props.hideMenu }
        onMenuOpen={ props.onMenuOpen }
        onMenuClose={ props.onMenuClose }
        closeMenuOnSelect={ props.closeMenuOnSelect }
        onBlur={ props.onBlur }
      />
    </FormControl>
  );
};

export default SelectControl;
