import { RouteConfig } from 'react-router-config';

import * as Page from '../pages';
import * as paths from './paths';

import { State as CurrentUserProfile } from 'reducers/user-profile';
import * as permission from 'utils/permissions';

import { getDeviceManagementRoutes } from './routes/device-management';
import { getRabbitRoutes } from './routes/rabbit';
import { getCallerRoutes } from './routes/caller';
import parkingRouters from './routes/parking';
import { getBaseStationRouters } from './routes/base-station';
import { getUserManagementRoutes } from './routes/user-management';
import { getProjectsRoutes } from 'routing/routes/projects';
import { getProvisioningRoutes } from 'routing/routes/provisioning/provisioning';
import { getProfileRoutes } from './routes/profile';
import { initDevicesStatesFilters } from '../models';
import { getDeviceManagementRedesignRoutes } from './routes/dm';

export const notFoundRoute: RouteConfig = {
  main: Page.NoMatch,
  label: 'Not found',
  routes: []
};

interface PermittedRoutesOptions {
  userData: CurrentUserProfile;
}

export function getPermitedRoutes({ userData }: PermittedRoutesOptions) {
  const { data: user } = userData;
  const Routes: RouteConfig[] = [
    ...getProfileRoutes(userData),
    {
      path: '/',
      exact: true,
      main: Page.Home,
      label: 'Home',
      icon: 'home',
      routes: []
    },
    {
      path: '/devices',
      label: 'Devices',
      icon: 'settings',
      routes: [
        {
          path: '/devices/devicehealth',
          exact: true,
          label: 'Devices Health',
          icon: 'assessment',
          main: Page.DevicesHealthPage,
          hiddenOnMainMenu: !permission.isAllowedToReadDevice(user),
          isShowByUrl: permission.isAllowedToReadDevice(user),
        },
        ...getDeviceManagementRoutes(userData),
        {
          path: paths.deviceStatesPath,
          pathParams: initDevicesStatesFilters,
          exact: true,
          icon: 'bug',
          label: 'Device States',
          main: Page.DeviceStates,
          hiddenOnMainMenu: !permission.isAllowedToReadDevice(user),
          isShowByUrl: permission.isAllowedToReadDevice(user),
        },
        {
          path: paths.deviceIncidentsHistoryPath,
          exact: true,
          label: 'Device Incidents History',
          icon: 'history',
          main: Page.IncidentsDashboard,
          hiddenOnMainMenu: !permission.isAllowedToReadMonitoring(user),
          isShowByUrl: permission.isAllowedToReadMonitoring(user),
        },
        {
          path: '/devices/map',
          exact: true,
          label: 'Device Map',
          hiddenOnMainMenu: !permission.isAllowedToReadDevice(user),
          isShowByUrl: permission.isAllowedToReadDevice(user),
        },
        ...getProvisioningRoutes(userData),
      ]
    },
    {
      path: '/occupancy',
      label: 'Parking',
      routes: [
        ...parkingRouters
      ]
    },
    {
      path: '/network',
      label: 'Network',
      icon: 'router',
      routes: [
        {
          path: '/network/connectivity-map',
          exact: true,
          main: Page.BaseStationConnectivityMap,
          label: 'Connectivity Map',
          icon: 'global',
          hiddenOnMainMenu: !permission.isAllowedToReadDevice(user),
          isShowByUrl: permission.isAllowedToReadDevice(user),
          routes: []
        },
        ...getBaseStationRouters(userData)
      ],
    },
    {
      path: '/api',
      label: 'API & Data',
      icon: 'messages',
      routes: [
        {
          path: '/api/raw-messages',
          exact: true,
          label: 'Raw Messages',
          icon: 'messages',
          main: Page.RawMessages,
          hiddenOnMainMenu: !permission.isAllowedToReadRawMessage(user),
          isShowByUrl: permission.isAllowedToReadRawMessage(user),
        },
        {
          path: '/api/lorawan-messages',
          exact: true,
          label: 'LoRaWAN Messages',
          icon: 'messages',
          main: Page.LorawanMessagesPage,
          // hiddenOnMainMenu: !permission.isAllowedToReadRawMessage(user),
          // isShowByUrl: permission.isAllowedToReadRawMessage(user),
        },
        ...getRabbitRoutes(userData),
        ...getCallerRoutes(userData),
      ]
    },
    {
      path: '/settings',
      label: 'Settings',
      icon: 'messages',
      hiddenOnMainMenu: !permission.isAllowedToReadUsers(user),
      isShowByUrl: permission.isAllowedToReadUsers(user),
      routes: [
        ...getProjectsRoutes(userData),
        ...getUserManagementRoutes(userData),
        {
          path: '/settings/lorawan',
          exact: true,
          label: 'LoRaWAN Sources',
          icon: 'ballot',
          main: Page.LoRaWanPage,
          hiddenOnMainMenu: false,
          isShowByUrl: true,
        }
      ],
    },

    ...getDeviceManagementRedesignRoutes(userData),
    notFoundRoute,
  ];
  return Routes;
}
