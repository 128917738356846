import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { ResponsiveLine, Serie, Datum } from '@nivo/line';
import outerTheme from 'styles/themes/outer';
import { props as LineChartsOptions } from './ChartsOptions/LineChartsOptions';
import { CustomSliceTooltip } from './CustomSliceTooltip';
import { axisFormat } from './ChartsOptions/CommonOptions';
import { LinearScale } from '@nivo/scales';
import { RawOnline } from 'models';

export enum VisibleDays {
  'month' = 30
}

interface Props {
  data: RawOnline[];
}

function isChartFilled(data: Serie[]): boolean {
  return data.some(line => line.data.some(({ y }) => y && y > 0));
}

const transformData = (online: Map<string, number>, data: RawOnline[]): Datum[] => {
  const res: Datum[] = [];

  for (const stat of data) {
    const day = moment(stat.demodulation_date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    online.set(day, stat.total);
  }

  for (const [key, value] of online) {
    res.push({ x: key, y: value });
  }

  return res;
};

export const RawOnlineChart = (props: Props): JSX.Element => {
  const prevMonth = moment().subtract(VisibleDays.month, 'days');

  const lastMonthData = props.data.filter(c => {
    return moment(c.demodulation_date, 'YYYY-MM-DD').isAfter(prevMonth);
  });

  // TODO: make type like `type DateStat = Map<string, number>`
  // And constructor
  const online: Map<string, number> = new Map();

  for (let i = VisibleDays.month - 1; i >= 0; i--) {
    const day = moment().subtract(i, 'days').format('YYYY-MM-DD');
    online.set(day, 0);
  }

  const data: Serie[] = [{
    id: 'Devices',
    data: transformData(online, lastMonthData)
  }];

  return (
    <Box
      data-filled={isChartFilled(data) ? 'true' : 'false'}
      data-testid="chart"
      width="100%"
      height="100%"
    >
      <ResponsiveLine
        { ...LineChartsOptions }
        data={ data }
        yScale={ {
          ...LineChartsOptions.yScale as LinearScale
        } }
        colors={ outerTheme.palette.primary.main }
        sliceTooltip={ CustomSliceTooltip }
        axisTop={ {
          ...LineChartsOptions.axisTop,
          legend: 'Devices Online',
        } }
        axisLeft={ { format: axisFormat } }
      />
    </Box>
  );
};
