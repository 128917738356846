import moment from 'moment';
import { History } from 'models/rabbit';
import { EnumRawMessagesFields } from 'models';
import { jsonPrettySafe } from 'utils/json';
import { useDevices } from 'hooks/device-management';
import { apiRawMessagesPath, apiLorawanRawMessagesPath } from 'routing/paths';

// components
import { Box, Grid, Paper, TableCell, TableRow, Typography } from '@material-ui/core';
import { DeviceLink, CustomLink } from 'components/Links';
import { RabbitMessageTypeName, ZoneLabel } from 'components/Labels';
import { CopyToClipboardButton } from 'components/Buttons';
import Link from '@material-ui/core/Link';
// styles
import useStyles from '../style';

interface AdditionalInfoProps {
  cols: number;
  data: History;
}

export const DetailInfo = ({ cols, data }: AdditionalInfoProps) => {
  const classes = useStyles();

  const params = {
    [EnumRawMessagesFields.traceid]: data.source_message_trace_id,
    [EnumRawMessagesFields.timeFrom]: moment(data.sending_time).subtract(10, 'minute'),
    [EnumRawMessagesFields.timeTo]: moment(data.sending_time).add(10, 'minute'),
  };

  const { devices } = useDevices({ params: {
    devices: [String(data.source_device_id)],
    zones: [],
    groups: [],
  } });
  const { protocol_name } = devices[0] || {};
  const rawMessageslink = protocol_name === 'unb' ?
    apiRawMessagesPath(params) :
    apiLorawanRawMessagesPath(params);

  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        <Paper>
          <Box p={ 2 }>
            <Grid container spacing={ 0 }>
              <Grid item xs={ 12 } md={ 6 }>
                <Typography variant="subtitle2" className={ classes.value }>
                  Device ID: &nbsp;&nbsp;
                  <Typography component="span">
                    <DeviceLink deviceId={ data.source_device_id } />
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Zone: &nbsp;&nbsp;
                  <Typography component="span">
                    {
                      data.source_device_zone_id ? <ZoneLabel zoneId={ data.source_device_zone_id } /> : '-'
                    }
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Source service name: &nbsp;&nbsp;
                  <Typography component="span">
                    { data.source_service_name ?? '-' }
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Source message trace ID: &nbsp;&nbsp;
                  <Typography component="span">
                    {
                      data.source_message_trace_id ?
                        <Link
                          component={ CustomLink }
                          color="secondary"
                          to={ rawMessageslink }
                        >
                          { data.source_message_trace_id }
                        </Link>
                        : '-'
                    }
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Source message type: &nbsp;&nbsp;
                  <Typography component="span">
                    {
                      data.source_message_type ? <RabbitMessageTypeName messageTypeId={ data.source_message_type } /> :  '-'
                    }
                  </Typography>
                </Typography>
                <Typography variant="subtitle2" className={ classes.value }>
                  Sending info: &nbsp;&nbsp;
                  <Typography component="span">
                    { data.sending_info ?? '-' }
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                { data.payload ?
                  <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mt={ -1 }>
                      <Typography variant="subtitle2">Body</Typography>
                      <CopyToClipboardButton
                        type="icon"
                        value={ data.payload }
                        copied={ `Payload was copied` }
                      />
                    </Box>
                    <Box border={ 1 } p={ 1 } borderRadius={ 3 } maxHeight={ 300 } overflow="scroll">
                      <pre className={ classes.jsonWrap }>
                        { jsonPrettySafe(data.payload) }
                      </pre>
                    </Box>
                  </>
                  : <Typography variant="subtitle2">Payload Body</Typography>
                }
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </TableCell>
    </TableRow>
  );
};