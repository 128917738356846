/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TickFormatter } from '@nivo/axes';
import { chartTheme } from '../ChartsStyles/ChartsStyles';
import { LegendProps } from '@nivo/legends';
import outerTheme from 'styles/themes/outer';
import Typography from '@material-ui/core/Typography';
import { BarDatum, Layer } from '@nivo/bar';
import moment, { Moment } from 'moment';

export enum VisibleDays {
  'month' = 30
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const Legends = require('@nivo/legends');

enum BarLayerType {
  Grid = 'grid',
  Axes = 'axes',
  Bars = 'bars',
  Markers = 'markers',
  Legends = 'legends',
}

/**
 * Convert 1000 to 1k string
 * @param value
 */
export function kFormatter(value: string | number): string {
  const num = Number(value);
  if (Math.abs(num) > 999) {
    return `${(Math.sign(num) * Math.round(Math.abs(num) / 1000))}k`;
  } else {
    return (Math.sign(num) * Math.abs(num)).toString();
  }
}

export enum PatternIds {
  lines1 = 'lines1',
  lines2 = 'lines2'
}

export const props = {
  data: [],
  margin: { top: 40, right: 10, bottom: 50, left: 50 },
  axisTop: null,
  axisRight: null,
  enableGridX: true,
  enableGridY: true,
  theme: chartTheme,
  legends: undefined,
  animate: true
};

export const axisFormat: TickFormatter = (n) => (Number(n) === n && n % 1 !== 0) ? '' : n.toString();

export const dateToMonthDay: TickFormatter = date => `${new Date(date).getMonth() + 1}-${new Date(date).getDate()}`;

export const legends: ({ dataFrom: 'keys' | 'indexes' } & LegendProps) = {
  dataFrom: 'keys',
  anchor: 'bottom',
  direction: 'row',
  justify: false,
  translateY: 70,
  itemWidth: 100,
  itemHeight: 20,
  itemsSpacing: 0,
  symbolSize: 20,
  itemDirection: 'left-to-right'
};

export const patternLines = {
  id: PatternIds.lines1,
  type: 'patternLines',
  background: 'inherit',
  rotation: -45,
  lineWidth: 6,
  spacing: 10
};

export const patternLines2 = {
  ...patternLines,
  id: PatternIds.lines2,
  color: outerTheme.palette.error.light,
  rotation: 45
};

const BarCustomLegend = ({ height, legends, width }: any) => {
  return (
    <React.Fragment>
      { legends.map((legend: any) => (
        <Legends.BoxLegendSvg
          key={ JSON.stringify(legend.data.map(({ id }: any) => id)) }
          { ...legend }
          containerHeight={ height }
          containerWidth={ width }
        />
      )) }
    </React.Fragment>
  );
};

export const stackedBarTooltip = (
  total: number,
  data: BarDatum,
  color: string,
  value: number,
  totalText: string,
  stackText: number | string,
) => {
  return (
    <>
      <Typography variant="subtitle1">
        { data.date }
      </Typography>
      <Typography>
        { totalText }: <strong>{ kFormatter(total) }</strong>
      </Typography>
      <Typography>
        { stackText }: <strong style={ { color } }>{ kFormatter(value) }</strong>
      </Typography>
    </>
  );
};

export const layers: Layer[] = [BarLayerType.Grid, BarLayerType.Axes, BarLayerType.Bars, BarLayerType.Markers, BarCustomLegend];


export const initalChartData = (timeFrom?: Moment, timeTo?: Moment): Map<string, number | null> => {
  const chartData: Map<string, number | null> = new Map();

  if (!timeTo) {
    timeTo = moment();
  }

  const diff = timeFrom ? timeTo.diff(timeFrom, 'days') : VisibleDays.month;

  for (let i = diff; i >= 0; i--) {
    const day = moment(timeTo).subtract(i, 'days').format('YYYY-MM-DD');
    chartData.set(day, null);
  }

  return chartData;
};

/**
 * nivo can't provide tick count
 * see: https://github.com/plouc/nivo/issues/138
 */
export const formatAxisBottom = (count: number, max: number): TickFormatter => {
  let tickCount = 0;
  return tick => scaledAxisBottom(tick, count, max, ++tickCount);
};

const scaledAxisBottom = (tick: number | string | Date, count: number, max: number, n: number): number | string => {
  const delimeter = Math.floor(count / max);

  if (delimeter > 1) {
    return n % delimeter ? '' : dateToMonthDay(tick);
  }

  return dateToMonthDay(tick);
};


