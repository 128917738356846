import { Template, TemplateSuite, Router } from 'models/caller';
import { getCallerTemplateSuiteDetailLocation } from 'routing/paths';
import { Link as RouterLink } from 'react-router-dom';
import { TemplateRouteSuite } from 'reducers/caller/routes-templates-suites';
import { useDialogWithItem } from 'hooks';
import { DoRoutesTemplatesSuitesLoad } from 'actions/caller';
import { useDispatch } from 'react-redux';
import { commonOptions } from 'utils/tables';
import { ConnectZoneAdditionalData, RemoveSuiteAdditionalData } from './types';

// components
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { PaginationFooter, TableLoadingLayout } from 'components/Table';
import { OwnerName } from 'components/Labels';
import { Link, Box } from '@material-ui/core';
import { CommonButton, DeleteButton } from 'components/Buttons';
import LensIcon from '@material-ui/icons/Lens';
import { ZoneLink } from 'components/Links';
import { ConnectZoneDialog } from '../ConnectZoneDialog/ConnectZoneDialogComponent';
import { DeleteDialog } from './DeleteDialog';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { truncatedCellsMuiTableTheme } from 'styles/themes';
import useStyles from './Style';

interface Props {
  isLoading: boolean;
  canViewOwner: boolean;
  suites: TemplateRouteSuite[];
  total: number;
}

enum TableCols {
  Suite = 'templateSuite',
  Zones = 'zones',
  Templates = 'templates',
  Owner = 'owner',
  Actions = 'actions'
}

const Component = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { suites, isLoading, canViewOwner } = props;
  const dispatch = useDispatch();
  const connectZoneDialog = useDialogWithItem<ConnectZoneAdditionalData>();
  const deleteDialog = useDialogWithItem<RemoveSuiteAdditionalData>();

  const data = suites.map(suite => ({
    [TableCols.Suite]: suite.name,
    [TableCols.Zones]: suite.routes,
    [TableCols.Templates]: suite.templates,
    [TableCols.Owner]: suite.owner_id,
    suite: suite,
  })) || [];

  const openConnectZoneDialog = (suite: TemplateSuite, routes: Router[]) => {
    connectZoneDialog.open({ suite, connectedZones: routes.map(router => router.zone_id) });
  };

  const onSuccess = () => {
    connectZoneDialog.close();
    deleteDialog.close();
    dispatch(DoRoutesTemplatesSuitesLoad({ limit: 999 }));
  };

  const deleteSuite = (suite: TemplateSuite, routes: Router[]) => {
    deleteDialog.open({ suite, routes });
  };

  const columns: MUIDataTableColumn[] = [
    {
      name: TableCols.Suite,
      label: 'Template suite',
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: dataIndex => {
          const { suite } = data[dataIndex];
          return (
            <Link
              component={ RouterLink }
              color="secondary"
              to={ getCallerTemplateSuiteDetailLocation(suite.template_suite_id) }
            >{ suite.name }</Link>
          );
        },
      },
    },
    {
      name: TableCols.Templates,
      label: 'Templates',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (templates: Template[]) => templates.map(tmpl =>
          <div className={ classes.customChip } key={ `${tmpl.template_suite_id}-${tmpl.template_id}` }>
            <LensIcon
              className={ classes.templateIconStatus }
              color={ tmpl.is_active ? 'secondary' : 'error' }
            />
            { tmpl.name }
          </div>
        ),
      },
    },
    {
      name: TableCols.Zones,
      label: 'Zones',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (routes: Router[]) => routes.map(route =>
          <div className={ classes.customChip } key={ route.zone_id }>
            <ZoneLink zoneId={ route.zone_id } />
          </div>
        ),
      },
    },
    {
      name: TableCols.Owner,
      label: 'Owner',
      options: {
        display: canViewOwner ? 'true' : 'excluded',
        customBodyRender: (ownerId: number) => <OwnerName ownerId={ ownerId } />
      }
    },
    {
      name: TableCols.Actions,
      label: ' ',
      options: {
        empty: true,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false,
        customBodyRenderLite: dataIndex => <Box display="flex" justifyContent="flex-end">
          <CommonButton
            type="text"
            label="connect zone"
            icon={ <></> }
            onClick={ () => openConnectZoneDialog(
              data[dataIndex].suite,
              data[dataIndex][TableCols.Zones],
            ) }
            dataTestId="connect-zone-button"
          />
          <DeleteButton
            type="text"
            label="delete"
            onClick={ () => deleteSuite(
              data[dataIndex].suite,
              data[dataIndex][TableCols.Zones],
            ) }
            dataTestId="delete-template-button"
          />
        </Box>
      },
    },
  ];

  const options: MUIDataTableOptions = {
    ...commonOptions,
    serverSide: true,
    count: props.total,
    setRowProps: (_rowData, dataIndex) => ({ 'data-template_suite_id': props.suites[dataIndex]?.template_suite_id }),
    customFooter: (count, _page, _rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <PaginationFooter
          count={ count }
          onChangeRowsPerPage={ changeRowsPerPage }
          onChangePage={ changePage }
        />
      );
    },
  };

  return (
    <>
      <MuiThemeProvider theme={ truncatedCellsMuiTableTheme(data.length) }>
        <TableLoadingLayout isLoading={ isLoading }>
          <MUIDataTable
            title={ null }
            data={ data }
            columns={ columns }
            options={ options }
          />
        </TableLoadingLayout>
      </MuiThemeProvider>

      {
        connectZoneDialog.isMounted && connectZoneDialog.item && <ConnectZoneDialog
          isOpen={ connectZoneDialog.isOpen }
          data={ connectZoneDialog.item }
          onClose={ connectZoneDialog.close }
          onCloseEnd={ connectZoneDialog.unmount }
          onSuccess={ onSuccess }
        />
      }

      {
        deleteDialog.isMounted && deleteDialog.item && <DeleteDialog
          data={ deleteDialog.item }
          isOpen={ deleteDialog.isOpen }
          onClose={ deleteDialog.close }
          onCloseEnd={ deleteDialog.unmount }
          onSuccess={ onSuccess }
        />
      }
    </>
  );
};

export default Component;
