import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'utils/datetime';
import { Zone } from 'models/device-management';
import { dmZonesPath } from 'routing/paths';
import { dmZonePath } from 'routing/paths';
import { useForm, Controller } from 'react-hook-form-v6';
import { useCreateZone, useUpdateZone, useDeleteZone } from 'hooks/device-management';
import { useZoneForm } from './useZoneForm';

// components
import { BlockTitle } from 'components/Block';
import { OwnersSingleSelectControl, ProjectSelectControl } from 'components/Controls';
import { Switch } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { SuccessButton } from 'components/Buttons';
import { Button } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DeleteZone } from './DeleteZoneButton';


interface Props {
  zone?: Partial<Zone>;
  mode?: 'CREATE' | 'EDIT';
  asSider?: boolean;
  onCreateSuccess?: () => void;
  onEditSuccess?: (zone: Partial<Zone>) => void;
  onCancel?: () => void;
}

export const ZoneFormWidget = ({ mode, zone, asSider, onCreateSuccess, onEditSuccess, onCancel }: Props): JSX.Element => {
  const isEdit = mode === 'EDIT';
  const title = mode === 'EDIT' ? 'Zone information' : 'Create zone';

  // form logic
  const {
    isAdmin,
    allowedToCreate,
    allowedToUpdate,
    allowedToDelete,
    defaultValues,
    getDefaultProject,
  } = useZoneForm(zone);
  const { control, getValues, setValue, watch, formState: { errors, isValid, isDirty }, reset, trigger } = useForm({
    defaultValues: defaultValues || { live_status: false },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // api
  const { push } = useHistory();
  const onZoneCreatedSuccess = (zone: Zone) => { onCreateSuccess ? onCreateSuccess() : push(dmZonePath(zone.id)); };
  const { createZone, isCreateZoneFetching } = useCreateZone(onZoneCreatedSuccess);
  const { updateZone, isUpdateZoneFetching } = useUpdateZone({
    onSuccess: (zone: Partial<Zone>) => { reset(zone); onEditSuccess && onEditSuccess(zone); },
    onError: () => { reset(); }
  });
  const { deleteZone, isDeleteZoneFetching } = useDeleteZone(() => { push(dmZonesPath); });

  // actions
  const siderActions = (<>
    { onCancel && (
      <Button color="inherit" onClick={ onCancel }>
        Close
      </Button>
    ) }
    {isEdit && allowedToUpdate && <SuccessButton
      label="Save"
      pending={ isUpdateZoneFetching }
      disabled={ !isDirty || !isValid }
      onClick={async () => {
        const v = getValues();
        v.id && await updateZone(v.id, v);
      }}
    />}
    {!isEdit && allowedToCreate && <SuccessButton
      label="Create"
      pending={ isCreateZoneFetching }
      disabled={ !isValid }
      onClick={async () => {
        const v = getValues();
        await createZone(v);
      }}
    />}
  </>);

  const nonSiderActions = (<>
    { allowedToDelete && defaultValues.id && (
      <DeleteZone
        zoneId={ defaultValues.id }
        pending={ isDeleteZoneFetching }
        onDelete={ () => {
          if (defaultValues.id) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            deleteZone(defaultValues.id);
          }
        } }
      />
    ) }

    { allowedToUpdate && isDirty && (
      <Button color="inherit" onClick={ () => { reset(); } }>
        Cancel
      </Button>
    ) }

    {isEdit && allowedToUpdate && <SuccessButton
      label="Save"
      pending={ isUpdateZoneFetching }
      disabled={ !isDirty || !isValid }
      onClick={async () => {
        const v = getValues();
        v.id && await updateZone(v.id, v);
      }}
    />}

    {!isEdit && allowedToCreate && <SuccessButton
      label="Create"
      pending={ isCreateZoneFetching }
      disabled={ !isValid }
      onClick={async () => {
        const v = getValues();
        await createZone(v);
      }}
    />}
  </>);

  return (
    <Box p={2}>
      <Box
        pb={2}
        display="flex"
        position={asSider ? 'absolute' : 'relative'}
        bottom={asSider ? 0 : ''}
        width={asSider && 'calc(100% - 64px)'}
      >
        <BlockTitle>
          {!asSider && title}
        </BlockTitle>

        {asSider && siderActions}

        {!asSider && nonSiderActions}

      </Box>
      <FormGroup>
        {isEdit && <Controller
          as={TextField}
          name="id"
          disabled
          label="Zone ID"
          control={control}
        />}
        <br />
        <Controller
          render={props => <TextField
            {...props}
            label="Zone name"
            error={!!errors.name}
            helperText={errors.name && 'Required'}
            disabled={ !allowedToCreate }
          />}
          name="name"
          control={control}
          rules={{ required: true }}
        />
        <br />
        {isEdit && <Controller
          as={TextField}
          control={control}
          name="creation_date"
          disabled
          label="Creation time"
          value={ defaultValues?.creation_date ? formatDateTime(defaultValues?.creation_date) : '' }
        />}
        {isEdit && <br />}
        <br />
        <Box ml={-2}>
          <Controller
            render={(props) => (
              <FormControlLabel
                control={
                  <Switch
                    checked={props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                    color="primary"
                  />
                }
                labelPlacement="start"
                label="Live status"
                disabled={ !allowedToCreate }
              />
            )}
            name="live_status"
            control={control}
          />
        </Box>
        <br />
        {isAdmin && <Controller
          render={props => <Box my={-1}>
            <OwnersSingleSelectControl
              {...props}
              selected={props.value}
              changeHandler={async v => {
                props.onChange(v);
                const defaultProjectId = getDefaultProject(v)?.id;
                setValue('project_id', defaultProjectId);
                await trigger('project_id');
              }}
              isClearable={false}
              error={errors.owner_id && 'Required'}
              isDisabled={ !allowedToCreate }
            />
          </Box>}
          name="owner_id"
          control={control}
          rules={{ required: true }}
        />}
        <br />
        <Controller
          render={props => <Box my={-1}>
            <ProjectSelectControl
              {...props}
              selected={props.value}
              onChange={v => {
                props.onChange(v);
              }}
              filter={ project => {
                const owner = watch('owner_id');
                return project.owner_id === owner;
              }}
              error={!errors.owner_id && errors.project_id && 'Required'}
              isDisabled={ !allowedToCreate || !watch('owner_id') }
            />
          </Box>}
          name="project_id"
          control={control}
          rules={{ required: true }}
        />
      </FormGroup>
      <br />
    </Box>);
};
