import { PageFilter } from './types';
import { isNil } from 'lodash';

// components
import { Box } from '@material-ui/core';
import { SelectedFilterChip } from 'components/Buttons/FilterButton/widgets/SelectedFilterChip';
import { ResetFiltersButton } from 'components/Buttons';
import { countFilterSet } from 'components/Buttons/FilterButton/utils';
import { BaseStationGeneration, OwnerName, ProjectName } from 'components/Labels';

interface Props {
  filter: PageFilter;
}

export const BsSelectedFilters = ({ filter: { filter, resetFilters } }: Props): JSX.Element => {
  const count = countFilterSet(filter);
  return (
    <>
      <Box flexGrow={ 1 }>
        { !isNil(filter.onlineStatus) && <SelectedFilterChip label={ 'Online Status' } value={ filter.onlineStatus }/> }
        { !isNil(filter.healthyStatus) && <SelectedFilterChip label={ 'Health Status' } value={ filter.healthyStatus }/> }
        { !isNil(filter.owner) && <SelectedFilterChip label={ 'Owner' } value={ <OwnerName ownerId={ filter.owner }/> }/> }
        { filter.projects.length > 0 && filter.projects.map(p => <SelectedFilterChip label={ 'Project' } value={ <ProjectName id={ p }/> }/>) }
        { filter.generation && <SelectedFilterChip
          label={ 'Generation' }
          value={ <BaseStationGeneration stationGeneration={ filter.generation }/> }
        />}
        { filter.country && <SelectedFilterChip label={ 'Country' } value={ filter.country }/> }
        { filter.city && <SelectedFilterChip label={ 'City' } value={ filter.city }/> }
        { filter.stationId && <SelectedFilterChip label={ 'Station Id' } value={ String(filter.stationId) }/> }
        { filter.eui && <SelectedFilterChip label={ 'EUI' } value={ filter.eui }/> }
      </Box>
      <ResetFiltersButton onReset={ resetFilters } count={ count }/>
    </>
  );
};
