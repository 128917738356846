import React from 'react';

// components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { DeviceProvisioningManagement } from 'components/Provisioning/DeviceProvisioningManagement';
import { FieldSkeleton } from 'components/Skeleton';
import { Device as ProvisioningDevice } from 'models/provisioning';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface Props {
  currentDevice?: ProvisioningDevice | null;
}

const DeviceProvisioningInfo: React.FC<Props> = ({ currentDevice }) => {
  const infoCss = useInfoBlockStyles();

  if (!currentDevice) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={ <ExpandMore /> }>
        Device provisioning
      </AccordionSummary>

      <AccordionDetails>
        <DeviceProvisioningManagement device={ currentDevice } />
      </AccordionDetails>
    </Accordion>
  );
};

export default DeviceProvisioningInfo;
