import { TableCell, TableRow, Typography } from '@material-ui/core';
import { LorawanMessage } from 'models';

interface Props {
  cols: number;
  message: LorawanMessage;
}

export const MessageDetailInfo = ({ cols, message }: Props) => {
  return (
    <TableRow>
      <TableCell colSpan={ cols }>
        <Typography variant="subtitle2">
          Device EUI: <Typography variant="caption">{ message.device_eui ?  message.device_eui.toUpperCase() : 'None' }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Trace ID: <Typography variant="caption">{ message.trace_id || 'None' }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Integration Type: <Typography variant="caption">{ message.integration_type === 'aws_iot' ? 'Nwave' :  (message.integration_type || '-') }</Typography>
        </Typography>
        <Typography variant="subtitle2">
          Data Rate: <Typography variant="caption">{ message.data_rate }</Typography>
        </Typography>
      </TableCell>
    </TableRow>
  );
};