import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'reducers';

import { Device, ExistingPosition } from 'models/device-management';
import { isAllowedToUpdatePosition } from 'utils/permissions';
import { combineIds } from 'utils/ids';
import { formatDateTime } from 'utils/datetime';
import { nameof } from 'utils/type-checking';

import { PositionFormFieldNames as FieldNames, PositionFormValues } from './PositionBlock/utils';

// components
import {
  Button,
  Accordion,
  AccordionDetails,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
} from '@material-ui/core';

import * as Fields from 'components/Form/Field';
import { FieldSkeleton } from 'components/Skeleton';

import { Map } from './Map';
import { PositionLabels } from './PositionLabels';

// styles
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface Props {
  position?: ExistingPosition;
  positionOwnerId?: number;
  idPrefix?: string;
  device?: Device;
  onZoneChange: () => void;
  onPositionGroupChange: () => void;
  changeLat: (value: React.ReactText) => void;
  changeLon: (value: React.ReactText) => void;
}

const PositionGeneralInfo = ({
  idPrefix,
  position,
  positionOwnerId,
  onZoneChange,
  onPositionGroupChange,
  device,
  changeLat,
  changeLon,
}: Props) => {
  const allowedToUpdatePosition = useSelector((state: RootState) => isAllowedToUpdatePosition(state.user.data));

  const form = useFormContext<PositionFormValues>();
  const formValues = form.watch();
  const { zone_id: zoneId } = formValues;

  const geoLatFieldId = combineIds(idPrefix, FieldNames.GEO_LAT);
  const infoCss = useInfoBlockStyles();

  if (!position) {
    return <FieldSkeleton className={ infoCss.field } />;
  }

  return (
    <Accordion expanded>
      <AccordionDetails>
        <FormGroup className={ infoCss.fields }>
          { position.id && (
            <TextField
              className={ infoCss.field }
              disabled
              label="Position ID"
              value={ position.id }
            />
          ) }

          { position?.id && (
            <Map
              position={ position }
              device={ device }
              mainMarkerPosition={ { lat: parseFloat(`${formValues.lat}`), lng: parseFloat(`${formValues.lon}`) } }
              handlePosition={ ({ lat, lng }) => {
                changeLat(lat);
                changeLon(lng);
              } }
            />
          ) }

          <FormGroup className={ clsx(infoCss.fields, infoCss.fieldGroup) }>
            <InputLabel className={ infoCss.field } htmlFor={ geoLatFieldId }>
              Geo
            </InputLabel>

            <FormGroup className={ infoCss.fields } row>
              <Fields.Text
                className={ infoCss.field }
                disabled={ !allowedToUpdatePosition }
                label="Latitude"
                name={ nameof<PositionFormValues>('lat') }
                type="number"
              />
              <Fields.Text
                className={ infoCss.field }
                disabled={ !allowedToUpdatePosition }
                label="Longitude"
                name={ nameof<PositionFormValues>('lon') }
                type="number"
              />
            </FormGroup>
          </FormGroup>

          {/* TODO: implement as a counter field instead? */ }
          <Fields.Text
            className={ infoCss.field }
            disabled
            label="Level"
            name={ nameof<PositionFormValues>('floor_number') }
            type="number"
          />

          <TextField
            className={ infoCss.field }
            disabled
            label="Creation date"
            value={ position.creation_date
              ? formatDateTime(position.creation_date)
              : ''
            }
          />

          {/* TODO: implement as a counter field instead? */ }
          <Fields.Text
            name={ nameof<PositionFormValues>('group_inner_id') }
            className={ infoCss.field }
            label="Group inner ID"
            disabled={!allowedToUpdatePosition}
            type="number"
          />

          <Fields.SelectPositionGroup
            label="Group"
            name={ nameof<PositionFormValues>('group_id') }
            isDisabled={ !allowedToUpdatePosition }
            ControlProps={ {
              className: infoCss.field,
            } }
            zoneIds={ zoneId ? [zoneId] : [] }
            onChange={ onPositionGroupChange }
          />

          <Fields.SelectZone
            name={ nameof<PositionFormValues>('zone_id') }
            isDisabled={ !allowedToUpdatePosition }
            ControlProps={ {
              className: infoCss.field
            } }
            onChange={ onZoneChange }
          />

          <Grid className={ infoCss.field } container>
            <Grid item xs>
              <Fields.Text
                name={ nameof<PositionFormValues>('custom_id') }
                disabled={ !allowedToUpdatePosition }
                fullWidth
                label="Custom ID"
              />
            </Grid>

            { allowedToUpdatePosition && (
              <Button onClick={ () => form.setValue('custom_id', uuidv4()) }>
                Generate
              </Button>
            ) }
          </Grid>

          <Grid className={ infoCss.field } container>
            <Grid item xs>
              <Fields.Text
                name={ nameof<PositionFormValues>('network_id') }
                disabled
                fullWidth
                label="Network ID"
              />
            </Grid>

          </Grid>

          <Grid className={ infoCss.field } container>
            <Grid item xs>
              <PositionLabels position={ position } positionOwnerId={ positionOwnerId } />
            </Grid>
          </Grid>
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default PositionGeneralInfo;
