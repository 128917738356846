import { useEffect } from 'react';
import { useAuthUserSelector, useDialog } from 'hooks';
import { BaseStation } from 'models/base-station';
import { canDeleteStation, canEditStation, canViewOwner } from 'utils/permissions';
import { useDispatch } from 'react-redux';
import { StartStationDataSync, StopStationDataSync } from 'actions/base-station';
import { useUnmount } from 'react-use';

// components
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { HealthyStatus, OnlineStatus, UptimeStatus } from '../../../../widgets';
import { BaseStationFrequency, BaseStationGeneration, BaseStationReceiverType, OwnerName } from 'components/Labels';
import { DeleteDialog } from './widgets/DeleteDialog';
import { EditDialog } from './widgets/EditDialog';
import { MessageButton } from './widgets/MessageButton';

// styles
import { useStyles } from '../styles';

interface Props {
  station: BaseStation;
}

export const BaseStationInfo = (props: Props): JSX.Element => {
  const { station } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const user = useAuthUserSelector();
  const deleteDialog = useDialog();
  const editDialog = useDialog();
  useEffect(() => {
    dispatch(StartStationDataSync(station.id));
  }, [dispatch, station.id]);
  useUnmount(() => dispatch(StopStationDataSync()));

  return (
    <Paper className={ classes.root }>
      <Grid container spacing={ 0 }>
        <Grid item xs={ 12 } md={ 8 }>
          <Box className={ classes.infoContainer }>
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Station ID</Typography>
              <Typography className={ classes.value }>{ station.id }</Typography>
            </Box>
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Generation</Typography>
              <Typography className={ classes.value }><BaseStationGeneration stationGeneration={ station.generation }/></Typography>
            </Box>
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Receiver</Typography>
              <Typography className={ classes.value }><BaseStationReceiverType receiverType={ station.receiver_type }/></Typography>
            </Box>
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Frequency (MHz)</Typography>
              <Typography className={ classes.value }>
                { station.frequency ? <BaseStationFrequency stationFrequency={ station.frequency }/> : ' - ' }
              </Typography>
            </Box>
            { canViewOwner(user) && (
              <Box className={ classes.infoBlock }>
                <Typography className={ classes.header }>Owner</Typography>
                <Typography className={ classes.value }>
                  <OwnerName ownerId={ station.owner_id }/>
                </Typography>
              </Box>
            ) }
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Uptime</Typography>
              <Typography className={ classes.value }>
                { <UptimeStatus stationStatus={ station.status } variant="text"/> }
              </Typography>
            </Box>
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Online status</Typography>
              <Typography className={ classes.value }>
                { <OnlineStatus stationStatus={ station.status } variant="text"/> }
              </Typography>
            </Box>
            <Box className={ classes.infoBlock }>
              <Typography className={ classes.header }>Health status</Typography>
              <Typography className={ classes.value }>
                { <HealthyStatus stationStatus={ station.status } variant="text"/> }
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <Box display="flex" justifyContent="flex-end" height="100%">
            <Box className={ classes.actionBlock }>
              <MessageButton station={ station } />
              { canEditStation(user) && (
                <Button
                  variant="text"
                  className={ classes.buttonEdit }
                  onClick={ editDialog.open }
                >EDIT&nbsp;STATION</Button>
              ) }
              { canDeleteStation(user) && (
                <Button
                  variant="text"
                  className={ classes.buttonDelete }
                  onClick={ deleteDialog.open }
                >REMOVE&nbsp;STATION</Button>
              ) }
            </Box>
          </Box>
        </Grid>
      </Grid>
      { editDialog.isMounted && (
        <EditDialog
          station={ station }
          isOpen={ editDialog.isOpen }
          onClose={ editDialog.close }
          onCloseEnd={ editDialog.unmount }
        />
      )}
      { deleteDialog.isMounted && (
        <DeleteDialog
          station={ station }
          isOpen={ deleteDialog.isOpen }
          onClose={ deleteDialog.close }
          onCloseEnd={ deleteDialog.unmount }
        />
      )}
    </Paper>
  );
};
