import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteUser } from 'actions/user-management/users';
import { User } from 'models/user-management';
import { formatUser } from 'utils/models';
import { dispatchAsync } from 'utils/store';
import { RootState } from 'reducers';
import { getUserBySub } from 'selectors/user-management';

// components
import { DeleteButton } from 'components/Buttons';
import { Skeleton } from '@material-ui/lab';

import { ConfirmationDialog } from 'components';

interface DeleteUserProps {
  userSub: User['sub'];
  onSuccess?: () => void;
}

export const DeleteUser: React.FC<DeleteUserProps> = (props) => {
  const user = useSelector((state: RootState) => getUserBySub(props.userSub, state));
  const dispatch = useDispatch();

  if (user === null) {
    return null;
  }

  if (typeof user === 'undefined') {
    return <Skeleton width={ 80 } height={ 50 } />;
  }

  return (
    <ConfirmationDialog
      title="Are you sure?"
      description={ `Are you sure that you want to delete user ${formatUser(user)}?` }
      renderTrigger={ (modal) => <DeleteButton type="icon" onClick={ modal.open } /> }
      onConfirm={ (modal) => {
        modal.setPending(true);

        dispatchAsync(dispatch, deleteUser(user.sub))
          .then(() => props.onSuccess?.())
          .catch(() => modal.setPending(false));
      } }
    />
  );
};
