import React from 'react';
import { BSConnectivityMapFilters } from 'pages/BaseStation/ConnectivityMap/types';
import { StationStatusTitle } from 'models/base-station-status';
import { isBool } from 'helpers';
// components
import { SelectOption, SingleSelectControl } from 'components/Controls/Select';
import { GroupedFilters } from 'components/Controls';

import { Box, Grid, Slider, Typography } from '@material-ui/core';
// styles
import useStyles from 'styles/filters';

const getBoolFromStr = (val?: string): boolean | undefined => {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  } else {
    return undefined;
  }
};

const onlineStatusOptions: SelectOption[] = [
  {
    label: StationStatusTitle.Offline,
    value: 'false'
  },
  {
    label: StationStatusTitle.Online,
    value: 'true'
  }
];

const healthyStatusOptions: SelectOption[] = [
  {
    label: StationStatusTitle.Healthy,
    value: 'true'
  },
  {
    label: StationStatusTitle.Unhealthy,
    value: 'false'
  }
];

interface Props {
  state: BSConnectivityMapFilters;
  setState: (filters: BSConnectivityMapFilters | ((filters: BSConnectivityMapFilters) => BSConnectivityMapFilters)) => void;
}

const BaseStationsConnectivityMapFilters = (props: Props) => {
  const classes = useStyles();

  const { state, setState } = props;

  const handleSelectOnlineStatus = (online?: string) => setState({ ...state, isOnline: getBoolFromStr(online) });
  const handleSelectHealthStatus = (healthy?: string) => setState({ ...state, isHealthy: getBoolFromStr(healthy) });

  const handleSelectOwner = (owner: number | undefined) => setState({ ...state, owner: Number(owner) || undefined });
  const handleSelectProjects = (projects: number[]) => setState(prevState => ({ ...prevState, projects }));
  const handleSelectZones = (zones: number[]) => setState(prevState => ({ ...prevState, zones }));
  const handleSelectGroups = (positionGroups: number[]) => setState({ ...state, positionGroups });
  const handleChangeConnectivity = (_e: unknown, newValue: number | number[]) => {
    setState({ ...state, connectivity: newValue as number[] });
  };
  return (
    <form autoComplete="off" className={ classes.gridRoot }>
      <GroupedFilters
        handleSelectOwner={ handleSelectOwner }
        owner={ state.owner }
        projects={ state.projects || [] }
        handleSelectProjects={ handleSelectProjects }
        zones={ state.zones }
        handleSelectZones={ handleSelectZones }
        groups={ state.positionGroups }
        handleSelectGroups={ handleSelectGroups }
        isProjectShow
        isZoneShow
        isGroupsShow
      />
      <Grid>
        <SingleSelectControl
          selected={ isBool(state.isOnline) ? `${ state.isOnline }` : undefined }
          label="Station Online Status"
          values={ onlineStatusOptions }
          changeHandler={ handleSelectOnlineStatus }
          name="onlineStatus"
          isClearable
        />
      </Grid>
      <Grid>
        <SingleSelectControl
          selected={ isBool(state.isHealthy) ? `${ state.isHealthy }` : undefined }
          label="Station Health Status"
          changeHandler={ handleSelectHealthStatus }
          values={ healthyStatusOptions }
          name="isHealthy"
          isClearable
        />
      </Grid>
      <Grid>
        <Box width="100%" marginBottom={ -2 }>
          <Typography gutterBottom>
            Connectivity range
          </Typography>
          <Slider
            value={ state.connectivity }
            onChange={ handleChangeConnectivity }
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            min={ 0 }
            max={ 100 }
            step={ 1 }
          />
        </Box>
      </Grid>
    </form>
  );
};

export default BaseStationsConnectivityMapFilters;
