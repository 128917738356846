import { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { useAuthUserSelector, useDialog } from 'hooks';
import { useSetDefaultProject } from 'hooks/user-managment';
import { useForm, useFormActionLoader } from 'hooks/form';
import { fetchUserPosition } from 'utils/map';

import { AttachLoraStationLocation, AttachStationLocation, CreateStationLocation } from 'actions/base-station';
import { BaseStation, LorawanStation, StationLocation, StationType } from 'models/base-station';
import { canViewOwner } from 'utils/permissions';
import { changeLocationByAddress, getAddressByLocation, isEmptyLocation } from '../../../widgets/utils';
import { Address, Position } from '../../../widgets/types';

// components
import { Box, Dialog, Tab, Tabs } from '@material-ui/core';
import { InfoDialog } from 'components/Dialogs';
import { LocationFormField, StationLocationForm } from '../../forms/LocationForm/StationLocationForm';
import { ReplaceLocationDialog } from './ReplaceLocationDialog';
import { MapBlock } from './widgets/MapBlock';
import { FormBlock } from './widgets/FormBlock';
import { LocationList } from './widgets/LocationList';

// styles
import useStyles from 'styles/dialogs';
import useStylesTabs from 'styles/tabs';

enum FormTab {
  ADD,
  SET
}

const initialLocation: StationLocation = {
  id: 0,
  owner_id: 0,
  project: undefined,
  country: '',
  city: '',
  address: '',
  postcode: undefined,
  notes: undefined,
  lat: 51.5073509, // center of London
  lon: -0.1277583, // center of London
  station_id: null
};

interface Props {
  isOpen: boolean;
  station: BaseStation | LorawanStation;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const AddLocationDialog = ({ isOpen, station, onClose, onCloseEnd }: Props): JSX.Element => {
  const { doAction } = useFormActionLoader();
  const classes = useStyles();
  const classesTabs = useStylesTabs();
  const user = useAuthUserSelector();
  const replaceDialog = useDialog();
  const foundDialog = useDialog();
  const [activeTab, setActiveTab] = useState<FormTab>(FormTab.ADD);
  const [selected, onSelect] = useState<StationLocation | undefined>(undefined);
  const [userPoint, setUserPoint] = useState<Position>();

  const [state, setState] = useState<StationLocation>(() => ({
    ...initialLocation,
    owner_id: station.owner_id,
    station_id: station.id,
  }));

  // we need to use hook here, cause we don't know when projects would load
  // setting default project value in initial state @TODO
  useSetDefaultProject({ state, setState });

  /* load user position by default  */
  useEffectOnce(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchUserPosition()
      .then(position => setUserPoint(position));
  });

  const form = useForm<StationLocation, LocationFormField>({
    initialState: initialLocation,
    state: state,
    onChange: setState,
    showFields: [
      ...(canViewOwner(user) ? [LocationFormField.owner_id] : []),
      LocationFormField.project,
      LocationFormField.country,
      LocationFormField.city,
      LocationFormField.address,
      LocationFormField.postcode,
      LocationFormField.notes,
    ],
  });

  const [found, setFound] = useState<Address | undefined>(undefined);

  // CREATE NEW LOCATION -> BIND TO STATION -> UPDATE DATA
  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    if (found) {
      foundDialog.open();
      return false;
    }

    doAction({
      action: CreateStationLocation(form.state),
      onSuccess: (location) => doAction({
        action: station.type === StationType.lorawan
          // @TODO UPDATE LOCATION & STATION DATA NOW INSIDE Attach method, move to hooks + clear cache
          ? AttachLoraStationLocation(station, location)
          : AttachStationLocation(station, location),
        onError: form.catchError,
        onSuccess: onClose
      }),
      onError: form.catchError,
    });
  };

  // BIND TO STATION -> UPDATE DATA
  const handleSet = () => {
    if (!selected) {
      return false;
    }

    if (selected.station_id) {
      replaceDialog.open();
      return false;
    }

    doAction({
      action: station.type === StationType.lorawan
        // @TODO UPDATE LOCATION & STATION DATA NOW INSIDE Attach method, move to hooks + clear cache
        ? AttachLoraStationLocation(station, selected)
        : AttachStationLocation(station, selected),
      onSuccess: () => onClose(),
    });
  };

  return (
    <>
      <Dialog
        open={ isOpen }
        onClose={ onClose }
        onExited={ onCloseEnd }
        fullScreen
      >
        <Box display="flex">
          <MapBlock
            found={ found }
            setFound={ setFound }
            findUser={ activeTab === FormTab.ADD && isEmptyLocation(state) ? userPoint : undefined }
            address={ activeTab === FormTab.ADD ? getAddressByLocation(state) : getAddressByLocation(selected) }
            onApply={ (address: Address) => setState(changeLocationByAddress(state, address)) }
          />
          <FormBlock
            title="Set location"
            btnAction={ activeTab === FormTab.ADD ? 'Add' : 'Set' }
            onAction={ () => activeTab === FormTab.ADD ? handleSave() : handleSet() }
            onClose={ onClose }
          >
            <>
              <Tabs
                centered
                variant="fullWidth"
                value={ activeTab }
                onChange={ (_, value) => setActiveTab(value as FormTab) }
                className={ classesTabs.root }
              >
                <Tab
                  label="Set new location"
                  value={ FormTab.ADD }
                  className={ classes.dialogTabLabel }
                />
                <Tab
                  label="Choose from existing"
                  value={ FormTab.SET }
                  className={ classes.dialogTabLabel }
                />
              </Tabs>
              { activeTab !== FormTab.ADD ? '' :
                <StationLocationForm { ...form } />
              }
              { activeTab !== FormTab.SET ? '' :
                <LocationList
                  station={ station }
                  selected={ selected }
                  onSelect={ onSelect }
                />
              }
            </>
          </FormBlock>
        </Box>
      </Dialog>
      { replaceDialog.isMounted && selected && <ReplaceLocationDialog
        station={ station }
        location={ selected }
        isOpen={ replaceDialog.isOpen }
        onClose={ replaceDialog.close }
        onCloseEnd={ replaceDialog.unmount }
        onConfirm={ onClose }
      /> }
      { foundDialog.isMounted && <InfoDialog
        name="unconfirmed-location-changes"
        label="Unconfirmed location changes"
        isOpen={ foundDialog.isOpen }
        onClose={ foundDialog.close }
        onCloseEnd={ foundDialog.unmount }
      >
          You've chosen the new position on the map, but didn't confirm changes.
          Please, CONFIRM the new position on the map or CANCEL the changes.
      </InfoDialog> }
    </>
  );
};
