import clsx from 'clsx';

// components
import FormControl from '@material-ui/core/FormControl';
import ReactSelect from './ReactSelect';
import { MultiSelectProps, SelectOption, ValueType } from './types';
// styles
import contentStyles from 'styles';

const MultiSelectControl = (props: MultiSelectProps): JSX.Element => {
  const classes = contentStyles();
  const selected: SelectOption[] = props.values.filter(option => props.selected.includes(option.value));
  const handleChange = (values: ValueType<SelectOption, true>) => {
    const selected: string[] = props.values
      .filter(option => values?.includes(option))
      .map(option => option.value);
    props.changeHandler(selected);
  };

  return (
    <FormControl
      disabled={ props.isDisabled }
      required={ props.isRequired }
      error={ props.error ? true : false }
      { ...props.ControlProps }
      className={ clsx(classes.formControl, props.ControlProps?.className) }
    >
      <ReactSelect
        isMulti={ true }
        closeMenuOnSelect={ false }
        label={ props.label }
        name={ props.name }
        placeholder={ props.placeholder }
        options={ props.values }
        onChange={ handleChange }
        error={ props.error }
        isClearable={ props.isClearable }
        isDisabled={ props.isDisabled }
        isLoading={ props.isLoading }
        isRequired={ props.isRequired }
        value={ selected.length ? selected : undefined }
        onBlur={ props.onBlur }
      />
    </FormControl>
  );
};

export default MultiSelectControl;
