import React from 'react';
import { useAuthUserSelector, useForm, useFormActionLoader } from 'hooks';
import { UpdateStation } from 'actions/base-station';
import { Station } from 'models/base-station';
import { canViewOwner } from 'utils/permissions';
// components
import { Button } from '@material-ui/core';
import { BaseStationForm, StationFormField } from '../../../../../widgets/forms/StationForm/BaseStationForm';
import { CommonDialog } from 'components/Dialogs';

interface Props {
  station: Station;
  isOpen: boolean;
  onClose: () => void;
  onCloseEnd?: () => void;
}

export const EditDialog = (props: Props): JSX.Element => {
  const { station, isOpen, onClose, onCloseEnd } = props;
  const user = useAuthUserSelector();
  const { doAction } = useFormActionLoader();
  const form = useForm<Station, StationFormField>({
    initialState: station,
    showFields: [
      ...(canViewOwner(user) ? [StationFormField.owner] : []),
      StationFormField.generation,
      StationFormField.frequency,
      StationFormField.receiverType,
    ],
  });

  const handleSave = () => {
    if (!form.validate()) {
      return false;
    }

    doAction({
      action: UpdateStation(form.state),
      onSuccess: onClose,
      onError: form.catchError,
    });
  };

  return (
    <CommonDialog
      isOpen={ isOpen }
      onClose={ onClose }
      onCloseEnd={ onCloseEnd }
      title={ `Edit station #${ station.id }` }
      content={ <BaseStationForm { ...form } /> }
      actions={
        <>
          <Button color="primary" onClick={ form.reset }>Reset</Button>
          <Button color="primary" variant="contained" onClick={ handleSave }>Save</Button>
        </>
      }
    />
  );
};
