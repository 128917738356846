import React from 'react';
import { useAuthUserSelector } from 'hooks';
import { Zone } from 'models/device-management';
import { canViewOwner, isAllowedToDeleteZones, isAllowedToUpdateZones } from 'utils/permissions';
import { useOwnerName, useZoneProjectName } from 'components/DeviceManagement/utils';

// components
import { Grid, Paper, Typography } from '@material-ui/core';
import { DeleteButton, DialogButton, EditButton } from 'components/Buttons';
import { FieldSkeleton } from 'components/Skeleton';
import { DeleteZoneDialog, EditZoneDialog } from 'components/DeviceManagement';

// styles
import useStyles from 'components/DeviceManagement/style';

interface Props {
  zone: Zone;
  onEdit: (zone: Partial<Zone>) => void;
  onDelete: (zone: Zone) => void;
}

export const ZoneManagement = ({ zone, onEdit, onDelete }: Props): JSX.Element => {
  const classes = useStyles();
  const user = useAuthUserSelector();
  const projectName = useZoneProjectName(zone);
  const ownerName = useOwnerName(zone.owner_id);

  return (
    <Paper className={ classes.root } data-testid="dm-zone-management">
      <Grid container alignItems="center">
        <Grid item lg={ 9 }>
          <Grid container spacing={ 3 } alignItems="center">
            <Grid item xs={ 3 }>
              <Typography>Zone Name</Typography>
              <Typography className={ classes.itemValue }>{ zone.name }</Typography>
            </Grid>
            <Grid item xs={ 3 }>
              <Typography>Project Name</Typography>
              <Typography component="div" className={ classes.itemValue }>
                { projectName ?? <FieldSkeleton height={ 24 }/> }
              </Typography>
            </Grid>
            { canViewOwner(user) && (
              <Grid item xs={ 3 }>
                <Typography>Owner Name</Typography>
                <Typography component="div" className={ classes.itemValue }>
                  { ownerName ?? <FieldSkeleton height={ 24 }/> }
                </Typography>
              </Grid>
            ) }
            <Grid item xs={ 3 }>
              <Typography>Monitoring of silent sensors</Typography>
              <Typography className={ classes.itemValue }>
                { zone.live_status ? 'Enabled' : 'Disabled' }
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={ 3 } className={ classes.buttonBlock }>
          <DialogButton
            permission={ isAllowedToUpdateZones }
            button={ open => <EditButton type="text" label="Edit" onClick={ open }/> }
            dialog={ props => (
              <EditZoneDialog
                { ...props }
                zone={ zone }
                onSuccess={ onEdit }
              />
            ) }
          />
          <DialogButton
            permission={ isAllowedToDeleteZones }
            button={ open => <DeleteButton type="text" label="Delete" onClick={ open }/> }
            dialog={ props => (
              <DeleteZoneDialog
                { ...props }
                zone={ zone }
                onSuccess={ onDelete }
              />
            ) }
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
