import { createTheme, alpha } from '@material-ui/core/styles';
import { merge } from 'lodash';

import * as palettes from 'styles/palettes';
import { createStyles, makeStyles } from 'styles/utils';

import outerTheme, { colors } from './outer';

export { outerTheme };

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const getTableTheme = (hideToolbar?: boolean) => (createTheme as any)({
  ...outerTheme,
  overrides: {
    ...outerTheme.overrides,
    MUIDataTable: {
      responsiveScrollFullHeight: {
        overflowX: 'auto'
      },
      responsiveBase: {
        overflow: 'visible'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        paddingBottom: 2,
        paddingTop: 2,
        [outerTheme.breakpoints.down('sm')]: {
          whiteSpace: 'nowrap',
        }
      },
      stackedCommon: {
        [outerTheme.breakpoints.down('sm')]: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '40vw',
          maxWidth: '100vw'
        }
      },
      cellHide: {
        color: outerTheme.palette.grey[600]
      }
    },
    MUIDataTableSelectCell: {
      root: {
        '& button': {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    },
    ...(hideToolbar && {
      MUIDataTableToolbar: {
        root: {
          display: 'none'
        }
      }
    }),
  }
});

export const useButtons = makeStyles(() => createStyles({
  saveBtn: {
    backgroundColor: colors.mainPrimary,
    color: colors.backgroundPaper,
    fontSize: 14,
    '&:hover': {
      opacity: '50%',
      backgroundColor: colors.mainPrimary
    }
  }
}));

interface MuiThemeOptions {
  embeddedTable?: boolean;
  overrides?: { [x: string]: unknown }
}

export const getMuiTheme = (options: MuiThemeOptions = {}) => {
  const { embeddedTable } = options;

  const commonOptions = {
    ...outerTheme,
    overrides: {
      ...outerTheme.overrides,
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent',
        }
      },
      MUIDataTable: {
        responsiveScrollFullHeight: {
          overflowX: 'auto'
        },
        paper: {
          boxShadow: 'none',
        },
      },
      MuiTableCell: {
        root: {
          paddingBottom: outerTheme.spacing(1),
          paddingTop: outerTheme.spacing(1),
          lineHeight: 'initial',
          fontSize: 14,
        },
        body: {
          color: colors.textPrimary,
        },
      },
      MUIDataTableHeadCell: {
        root: {
          color: colors.textPrimary,
          fontWeight: 'normal',
          paddingBottom: 1,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: alpha(colors.darkgrey, 0.15),
        },
        toolButton: {
          whiteSpace: 'nowrap'
        },
      },
      MUIDataTableBodyRow: {
        root: {
          backgroundColor: colors.backgroundPaper,
        },
      },
    },
  };

  const embedTableOptions = {
    overrides: {
      MUIDataTable: {
        paper: {
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
        },
        responsiveBase: {
          flexGrow: 1,
        }
      },
      MuiTableCell: {
        root: {
          borderBottom: '1px solid #e0e0e0',
        },
        footer: {
          borderBottom: 'none',
        },
        body: {
          paddingTop: 11,
          paddingBottom: 11,
          wordBreak: 'break-word',

          '& [class*=MuiSkeleton-root]': {
            height: 33,
          },
        },
      },
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'nowrap',
          paddingTop: 12,

          '& [class*=MuiSkeleton-root]': {
            height: 17,
          },
        },
      },
    },
  };

  const fullSizeTableOptions = {
    overrides: {
      MUIDataTable: {
        tableRoot: {
          width: '96%',
          margin: '0 auto',
        },
        paper: {
          backgroundColor: outerTheme.palette.background.default,
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: '8px solid rgb(250, 250, 250)',
          borderTop: `9px solid ${outerTheme.palette.background.default}`,
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: outerTheme.palette.background.default,
        },
      },
    },
  };

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  return (createTheme as any)(merge(
    {},
    commonOptions,
    embeddedTable ? embedTableOptions : fullSizeTableOptions
  ));
};

export const truncatedCellsMuiTableTheme = (rowsCount: number, options: MuiThemeOptions = {}) => {
  const defaultTheme = getMuiTheme(options);

  return {
    ...defaultTheme,
    overrides: {
      ...defaultTheme.overrides,
      MUIDataTableBodyCell: {
        root: {
          maxWidth: rowsCount > 0 ? '30vw' : '100vw', // full width on empty table
          overflow: 'hidden',
          textOverflow: 'ellipsis',

          [outerTheme.breakpoints.down('sm')]: {
            maxWidth: '100vw',
          }
        }
      },
    }
  };
};

// TODO: merge all custom color intentions with the app-wide palette
// when it becomes supported to style the components this way.
// So, we wouldn't have to override the outer theme anymore.
// https://github.com/mui-org/material-ui/issues/13875

export const dangerTheme = createTheme({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: palettes.danger,
  },
});

export const successTheme = createTheme({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: palettes.success,
  },
});

export const warningTheme = createTheme({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: palettes.warning,
  },
});
