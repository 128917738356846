// components
import { Button } from '@material-ui/core';
import { ZoneLabel, ConfirmationDialog } from 'components';

// styles
import { MuiThemeProvider } from '@material-ui/core/styles';
import { dangerTheme } from 'styles/themes';

interface DeleteZoneProps {
  zoneId: number;
  pending: boolean;
  onDelete: () => void;
}

export const DeleteZone: React.FC<DeleteZoneProps> = ({ onDelete, zoneId, pending }) => {
  return (
    <ConfirmationDialog
      title={(
        <>
          Are you sure you want to delete zone{' '}
          <strong>
            <ZoneLabel zoneId={zoneId} noLink />
          </strong>?
        </>
      )}
      pending={pending}
      description={
        'The linked groups and positions will be deleted. ' +
        'The linked devices will be unbound.'
      }
      renderTrigger={modal => (
        <MuiThemeProvider theme={dangerTheme}>
          <Button color="primary" variant="contained" onClick={modal.open}>
            Delete
          </Button>
        </MuiThemeProvider>
      )}
      onConfirm={() => {
        onDelete();
      }}
    />
  );
};
