import React from 'react';
import { styled } from 'styles/utils';
import { useAuthUserSelector, useDialog } from 'hooks';
import { LorawanStation, BaseStation } from 'models/base-station';
import { canEditStation } from 'utils/permissions';
// components
import { Box, Typography } from '@material-ui/core';
import { PlusButton } from 'components/Buttons';
import { AddLocationDialog } from '../../../../widgets/Dialogs';

export const OutlinedBox = styled(Box)(({ theme }) => ({
  borderWidth: 2,
  borderStyle: 'solid',
  borderRadius: 0,
  width: '100%',
  height: '100%',
  color: theme.palette.primary.main,
}));

interface Props {
  station: BaseStation | LorawanStation;
}

export const EmptyLocation = ({ station }: Props): JSX.Element => {
  const user = useAuthUserSelector();
  const setLocationDialog = useDialog();
  return (
    <OutlinedBox display="flex" justifyContent="center" alignItems="center">
      {
        canEditStation(user)
          ? (
            <>
              <PlusButton type="icon" label="Set location" onClick={ setLocationDialog.open }/>
              <Typography variant="h6">Set location</Typography>
            </>
          )
          : (
            <>
              <Typography variant="h6">Not location</Typography>
            </>
          )
      }
      { setLocationDialog.isMounted && <AddLocationDialog
        station={ station }
        isOpen={ setLocationDialog.isOpen }
        onClose={ setLocationDialog.close }
        onCloseEnd={ setLocationDialog.unmount }
      /> }
    </OutlinedBox>
  );
};
