import { apiRawMessagesPath } from 'routing/paths';
import { EnumRawMessagesFields } from 'models/raw-messages';
import { Station } from 'models/base-station';

// components
import MessageIcon from '@material-ui/icons/Message';
import { CommonButton } from 'components/Buttons';

interface Props {
  station: Station;
}

export const MessageButton = ({ station }: Props): JSX.Element => {

  const messagePath = apiRawMessagesPath({
    [EnumRawMessagesFields.bsid]: station.id
  });

  return (
    <CommonButton
      type="icon"
      label="Messages"
      icon={ <MessageIcon/> }
      LinkTo={ messagePath }
      ButtonProps={ {
        size: 'small'
      } }
    />
  );
};
