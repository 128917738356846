import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { bindDeviceToPosition, replaceDevice } from 'actions/device-management/devices';
import { findPositions } from 'actions/device-management/positions';
import { NotifyError } from 'actions/notifier';
import { fetchBoundDeviceId } from 'clients/device-management';
import { Zone, Device, Position, Group } from 'models/device-management';
import { RootState as State } from 'reducers';
import { getPositionsArray } from 'selectors/device-management/positions';
import { useDataLoaderOnce } from 'utils/components';
import { Defined, formatPosition, isNil } from 'utils/models';
import { dispatchAsync } from 'utils/store';

// components
import { FormGroup, Paper, Accordion, AccordionDetails, Box } from '@material-ui/core';

import { Button, ConfirmationDialog, ConfirmationDialogProps, SingleSelectControl } from 'components';
import { BlockTitle, BlockToolbar } from 'components/Block';
import { ZoneSelectControl, GroupSelectControl } from 'components/Controls';
// styles
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import { successTheme } from 'styles/themes';
import { useInfoBlockStyles } from 'styles/infoBlockStyles';

interface PositionBindingProps {
  device: Device;
  onSuccess?: () => void;
}

interface FormValues {
  zoneId: Defined<Zone['id']> | null;
  groupId: Defined<Group['id']> | null;
  positionId: Defined<Position['id']> | null;
}

function updateFormValues(updatedValues: Partial<FormValues>): (values: FormValues) => FormValues {
  return (values) => {
    const nextValues: FormValues = { ...values, ...updatedValues };

    if (nextValues.zoneId !== values.zoneId) {
      nextValues.groupId = isNil(updatedValues.groupId) ? null : updatedValues.groupId;
    }

    if (nextValues.groupId !== values.groupId) {
      nextValues.positionId = isNil(updatedValues.positionId) ? null : updatedValues.positionId;
    }

    return nextValues;
  };
}

interface ConfirmationDialogTextGetterOptions {
  device: Device;
  selectedPositionDeviceId: Device['device_id'] | null;
}

function getConfirmationDialogText(
  options: ConfirmationDialogTextGetterOptions,
): Partial<ConfirmationDialogProps> | null {
  if (options.device.damaged_status) {
    return {
      title: 'Are you sure?',
      description: 'Do you really want to bind the damaged device to the selected position?'
    };
  }

  if (!isNil(options.selectedPositionDeviceId)) {
    return {
      title: 'Are you sure?',
      description: 'Do you really want to replace the device bound to the selected position?'
    };
  }

  return null;
}

const PositionBinding: React.FC<PositionBindingProps> = (props) => {
  const [{ zoneId, groupId, positionId }, setFormValues] = useState<FormValues>({
    zoneId: null,
    groupId: null,
    positionId: null,
  });
  const [submitPending, setSubmitPending] = useState(false);

  const dispatch = useDispatch();
  const notifyError = (message: string) => dispatch(NotifyError(message));

  const positions = useSelector((state: State) => getPositionsArray(state));

  useDataLoaderOnce({
    key: groupId,
    load: () => !isNil(groupId) && dispatch(findPositions({ groupIds: [groupId] })),
    shouldStartLoading: !isNil(groupId),
  });

  const { loading: devicePending, value: selectedPositionDeviceId } = useAsync(async () => {
    if (isNil(positionId)) {
      return null;
    }

    return fetchBoundDeviceId({ positionId }).catch(e => {
      notifyError(e.message);
      throw e;
    });
  }, [positionId]);

  const infoCss = useInfoBlockStyles();

  async function submit(): Promise<void> {
    if (isNil(positionId)) {
      return Promise.reject('Position binding: Position has not been selected.');
    }

    if (selectedPositionDeviceId) {
      await dispatchAsync(dispatch, replaceDevice({
        oldDevice: selectedPositionDeviceId,
        newDevice: props.device.device_id,
      }));
    } else {
      await dispatchAsync(dispatch, bindDeviceToPosition({
        deviceId: props.device.device_id,
        positionId,
      }));
    }

    props.onSuccess?.();
  }

  return (
    <Paper>
      <BlockToolbar>
        <BlockTitle>
          <Box padding="0px 16px">
            Bind position
          </Box>
        </BlockTitle>
      </BlockToolbar>

      <Accordion expanded>
        <AccordionDetails>
          <FormGroup className={ infoCss.fields }>
            <ZoneSelectControl
              isClearable
              isMulti={ false }
              selected={ isNil(zoneId) ? undefined : zoneId }
              onChange={ (nextZoneId) => setFormValues(
                updateFormValues({ zoneId: nextZoneId }),
              ) }
              ControlProps={ { className: infoCss.field } }
            />
            <GroupSelectControl
              isClearable
              isDisabled={ isNil(zoneId) }
              isMulti={ false }
              zoneIds={ isNil(zoneId) ? [] : [zoneId] }
              selected={ isNil(groupId) ? undefined : groupId }
              onChange={ (nextGroupId) => setFormValues(
                updateFormValues({ groupId: nextGroupId }),
              ) }
              ControlProps={ { className: infoCss.field } }
            />

            <SingleSelectControl
              ControlProps={ { className: infoCss.field } }
              isDisabled={ isNil(groupId) }
              name="positionId"
              label="Position"
              selected={ isNil(positionId) ? undefined : `${positionId}` }
              values={ positions
                .filter(p => p.group_id === groupId)
                .map(p => ({ value: `${p.id}`, label: formatPosition(p) }))
              }
              changeHandler={ (nextPositionId) => setFormValues(
                updateFormValues({ positionId: isNil(nextPositionId) ? null : Number(nextPositionId) }),
              ) }
            />

            <div className={ infoCss.actions }>
              <ConfirmationDialog
                { ...(
                  typeof selectedPositionDeviceId !== 'undefined' &&
                  getConfirmationDialogText({
                    device: props.device,
                    selectedPositionDeviceId,
                  })
                ) }
                renderTrigger={ (modal) => (
                  <ThemeProvider theme={ successTheme }>
                    <Button
                      CircularProgressProps={ { color: 'secondary', size: '1.1rem' } }
                      className={ infoCss.button }
                      color="primary"
                      pending={ devicePending || submitPending }
                      variant="contained"
                      onClick={ () => {
                        if (isNil(positionId)) {
                          notifyError('Position binding: you must select Zone, Group, and Position to submit.');
                          return;
                        }

                        if (typeof selectedPositionDeviceId === 'undefined') {
                          return;
                        }

                        if (props.device.damaged_status) {
                          modal.open();
                          return;
                        }

                        if (!isNil(selectedPositionDeviceId)) {
                          modal.open();
                          return;
                        }

                        setSubmitPending(true);
                        submit().finally(() => setSubmitPending(false));
                      } }
                    >
                      Bind
                    </Button>
                  </ThemeProvider>
                ) }
                onConfirm={ (modal) => {
                  modal.setPending(true);

                  submit()
                    .then(modal.close)
                    .catch(() => modal.setPending(false));
                } }
              />
            </div>
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default PositionBinding;
